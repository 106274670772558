import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { getAuth } from "@firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import ImageButton from "../../components/ImageButton"
import VideoPlayer from "../../components/videoPlayer";
import { updateISHeader, renderLogo } from "../../shared/utils/dispatchHelpers";
import awardPoints from "../../shared/utils/rewards";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import WELCOME_DATA from "./constant";
import "./index.css"
import winObj from "../../shared/utils/window";

const { goals } = WELCOME_DATA

function WelcomeScreen( {store, dispatch } ){

    const location = useLocation();
    const [ auth ] = useState(getAuth());
    const [ buttonState, setButtonState ] = useState(true);
    const [ usersGoals, setUsersGoals ] = useState(initUsersGoals(goals, store.goals))
    const [ heading, setHeading ] = useState("")
    const [ buttonText, setButtonText ] = useState("")

    const urlParams = new URLSearchParams(location.search);
    const [ isGoalSettingComplete, setIsGoalSettingComplete ] = useState( (urlParams.has('note') && urlParams.get('note').toLowerCase() === "thanks" ));

    useEffect(()=>{

        const selectedGoal = usersGoals.find((goal)=>goal.selected)
        setButtonState( selectedGoal ? false : true );

    }, [usersGoals])

    useEffect(()=>{
        dispatch(updateISHeader(false, true));
        dispatch( renderLogo() );
    },[dispatch])

    useEffect(()=>{
        
        if ( isGoalSettingComplete ) {
            setHeading("We're happy you are here!")
            setButtonText("Continue")
        } else {
            setHeading("What are your motivations?")
            setButtonText("Become Inward Strong")
        }

    },[isGoalSettingComplete])

    const onGoalSelected = (selectedGoal) => {
        
        const updatedUserGoals = usersGoals.map((goal) => {
            if (goal.id === selectedGoal.id)
                return { ...goal, selected: !goal.selected } 
            else 
                return goal
        })

        // Fire analytics for wellness goals each time it is set.
        if ( selectedGoal && !selectedGoal.selected ) {
            logAnalyticEvent(Events.WELLNESS_GOALS, createEventData({ goal: selectedGoal.name, sponsoring_org: store.userProfile.orgSponsor }));
        }

        setUsersGoals( updatedUserGoals );
        
        dispatch( { type: "GOALS", data: updatedUserGoals } )
    }

    const onNext = () => {
        if ( isGoalSettingComplete ){

            if ( auth && auth.currentUser ) {
                const updatedtokens = awardPoints( auth.currentUser.uid, 'WELCOME_GOAL_COMPLETION', store.tokens, store.userProfile.orgSponsor );
                dispatch( { type: 'TOKENS', data: updatedtokens } )
            }

            winObj.location.href = "/#/CarePathway"
        } else {
            setIsGoalSettingComplete(true);
            winObj.location.href = "/#/Welcome?note=thanks"
        }
    }

    return(
        <div className="Welcome-Container">
            <h2> Welcome { store.userProfile && store.userProfile.displayName ? store.userProfile.displayName : ""} </h2>
            <h4> { heading } </h4>

            { !isGoalSettingComplete ? 
                <div className="list-goals">
                    { 
                        usersGoals.map(( goal ) =>
                            <ImageButton pressed={goal.selected} key={goal.id} className="square" value={goal.name} logo={goal.pictureURL} onClick={()=>onGoalSelected(goal)} />
                        )
                    }
                </div>
                : null }

            { isGoalSettingComplete ? 
                <div className="video-container">
                    <VideoPlayer src="https://player.vimeo.com/video/647065276" autoPlay={true} displayControls={true} />
                </div>
                : null }

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value={buttonText} onClick={onNext} /> 
            </div>

        </div>
    )
}

export default StoreConnect(WelcomeScreen)


// HELPERS

/**
 * Returns a list of goals based on user's choice
 */
function initUsersGoals( goals, usersGoals ){
    return goals.map((goal, index)=>{
        return { ...goal, selected: usersGoals.length > 0 ? usersGoals[index].selected : false }
    })
}