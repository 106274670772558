import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ISButton from "../button";
import STRESS_IMG_URL from "./assets/stress.png";
import COPING_IMG_URL from "./assets/coping.png";
import "./index.css";

function StressLogScale( { type, onResult, scaleValues } ){

    const [ scaleTitle, setScaleTitle ] = useState("");
    const [ scaleImg, setScaleImg ] = useState("");
    const [ scaleRatings, setScaleRatings ] = useState(null);
    const [ scaleButtonName, setScaleButtonName ] = useState("")
    const [ inputSelectedRange, setInputSelectedRange ] = useState("linear-gradient(to right, #9B5DE5 0%, #9B5DE5 0%, #E6E4EA 0%, #E6E4EA 100%)");
    const [ defaultScaleValue, setDefaultScaleValue ] = useState(1);
    const [ scaleValue, setScaleValue ] = useState(1);
    const [ scaleReadings, setScaleReadings ] = useState(null);
    const [ buttonState, setButtonState ] = useState(true);

    useEffect(() => {

        if ( type === `coping` ){
            setScaleTitle("Coping Check-In");
            setScaleImg(COPING_IMG_URL);
            setScaleButtonName("Finish")
            setDefaultScaleValue(1);
            setButtonState(true)
        } else {
            setScaleTitle("Stress Check-In");
            setScaleImg(STRESS_IMG_URL);
            setScaleButtonName("Next")
            setDefaultScaleValue(1)
            setButtonState(true);
        }

    }, [ type ])


    useEffect(() => {

        if (scaleValues && scaleValues.logValues ) {
            setScaleRatings( scaleValues.logValues[type] );
        }

    }, [ scaleValues, type ])

    useEffect(() => {
        if ( scaleValue && scaleRatings ) {
            if ( typeof(scaleValue) === "number" ) 
                setScaleReadings(scaleRatings[ "init" ]);
            else 
                setScaleReadings(scaleRatings[ scaleValue.toString() ]);
        }

    }, [scaleValue, scaleRatings])

    useEffect(() => {
        if ( defaultScaleValue && scaleRatings) {
            let value = (defaultScaleValue-1)/(10-1)*100
            setInputSelectedRange('linear-gradient(to right, #9B5DE5 0%, #9B5DE5 ' + value + '%, #E6E4EA ' + value + '%, #E6E4EA 100%)');
            setScaleValue( defaultScaleValue );
        }
    // eslint-disable-next-line
    }, [defaultScaleValue])

    const onSaveScaleMeasure = () => {
        setDefaultScaleValue(null);
        onResult( { type, scaleValue } );
    }

    const onRangeChange = (e) => {
        let nativeInputRange = e.nativeEvent.srcElement;
        let value = (nativeInputRange.value-nativeInputRange.min)/(nativeInputRange.max-nativeInputRange.min)*100
        setScaleValue(nativeInputRange.value);
        setInputSelectedRange('linear-gradient(to right, #9B5DE5 0%, #9B5DE5 ' + value + '%, #E6E4EA ' + value + '%, #E6E4EA 100%)');
        setButtonState(false);
    }

    return (
        <div className="StressLogScale">
            <h2> { scaleTitle } </h2>
            <div className={"ScaleDetails " + type}>
                <img src={ scaleImg } alt="Scale" />
                { scaleReadings ? 
                    <>
                        <h4 className={scaleReadings.rate}> { scaleReadings.rate } </h4>
                        <p key={scaleReadings.rate}> { scaleReadings.blurb } </p>
                    </>
                : null }
            </div>

            <div className="ScaleValue"> { scaleValue } </div>

            <div className="ScaleRange">
                <div className="value"> 1 </div>
                <input key={type} type="range" min="1" max="10" className="slider" id="myRange" defaultValue={defaultScaleValue} onInput={onRangeChange} style={ {background: inputSelectedRange}} />
                <div className="value"> 10 </div>
            </div>

            <ISButton disabled={buttonState} value={scaleButtonName} onClick={onSaveScaleMeasure} />

        </div>
    )

}   

StressLogScale.propTypes = {
    type: PropTypes.string,
    scaleValues: PropTypes.object
}

StressLogScale.defaultProps = {
    type: "stress"
}

export default StressLogScale