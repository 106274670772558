import React, { useRef, useState, useEffect } from "react";
import { getAuth, updatePassword, EmailAuthProvider, signInWithCredential, reauthenticateWithCredential } from "@firebase/auth";
import { useHistory } from 'react-router-dom';
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { createMessageModal } from "../../shared/utils/modals";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import SUCCESS_IMAGE_ICON from "../../app/assets/checkbox-circle-line.svg";
import ERRORS from '../../app/constant';
import "./index.css";

var { AUTH_ERRORS } = ERRORS;

function UpdatePassword({ dispatch }){

    const [ auth ] = useState(getAuth());
    const [ buttonState, setButtonState ] = useState(true);
    const oldPassword = useRef();
    const password = useRef();
    const confirmPassword = useRef();
    let history = useHistory();

    useEffect(()=>{
        dispatch(updateISHeader(true, null, null))

        if ( auth.currentUser && auth.currentUser.providerData && auth.currentUser.providerData.length > 0 ) {
            let passwordProvider = auth.currentUser.providerData.find( (provider) => {
                return (provider.providerId.toLowerCase() === "password")
            })

            if ( !passwordProvider ) {
                history.goBack();
            }
        }

    // eslint-disable-next-line
    }, [dispatch])

    const onPasswordChange = () => {
        setButtonState(oldPassword.current.value.length === 0 || (password.current.value.length === 0 || confirmPassword.current.value.length === 0))
    }

    const onUpdatePassword = async () => {

        let currentPwd = oldPassword.current.value;
        let pwd = password.current.value;
        let cPwd = confirmPassword.current.value;

        if ( pwd !== cPwd ){
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.CREATE_ACCOUNT_ERRORS["auth/password-not-match"]) } })
            return;
        }

        dispatch({ type: 'LOADER', data: { play: true } });

        let authCredential = EmailAuthProvider.credential( auth.currentUser.email, currentPwd);
        
        signInWithCredential(auth, authCredential)
            .then((userCredential) => {
                return reauthenticateWithCredential(userCredential.user, authCredential)
            })
            .then((userCredential) => {
                return updatePassword( userCredential.user, pwd );
            })
            .then(() => {
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Success", SUCCESS_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Your password has been changed" ) } })
            })
            .catch((err) => {
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Please ensure that your old password is correct") } })
            })
            .finally(() => {
                dispatch({ type: 'LOADER', data: { play: false } });
            })

    }


    return (
        <div className="UpdatePassword-Container">
            <h2> Change your password </h2>

            <div className="Password InputField">
                <p> Current password </p>
                <input type="password" ref={oldPassword} id="password" name="password" onChange={onPasswordChange} />
            </div>

            <div className="Password InputField">
                <p> Type new password </p>
                <input type="password" ref={password} id="new-password" name="new-password" onChange={onPasswordChange} />
            </div>

            <div className="Password InputField">
                <p> Retype new password </p>
                <input type="password" ref={confirmPassword} id="password-confirm" name="password-confirm" onChange={onPasswordChange} />
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Save" onClick={onUpdatePassword} /> 
            </div>

        </div>
    )

}

export default StoreConnect(UpdatePassword)