import React from "react";
import PropTypes from "prop-types";
import LOCKED_ICON from "./assets/lock.svg";
import "./index.css";

/**
 * Renders a single card within a IScarousel component
 * @param {props} card prop
 */
function CarouselCard({ card, onCardSelected }){

    const onCardClicked = (e) =>{
        if ( card.link.length <= 0 ) { 
            e.preventDefault();
            onCardSelected( card );
        }
    }

    return (

        <a href={card.link} className="link" onClick={onCardClicked} >
            <div className={ card.isLocked ? "Card locked" : "Card" }>
                <img className="background" src={card.artwork} alt={card.title} />

                { card.flagText ?
                    <div className="flagText">
                        <p> { card.flagText } </p>
                    </div>
                : null }

            </div>

            <div className="title">
                <p> { card.title } </p>
            </div>

            { card.isLocked ?
                <div className="lockedIcon">
                    <img src={LOCKED_ICON} alt="locked" />
                </div> 
            : null }
        </a>
    )
}

CarouselCard.propTypes = {
    card: PropTypes.shape({
        title: PropTypes.string,
        artwork: PropTypes.string,
        link: PropTypes.string,
        isLocked: PropTypes.bool,
        flagText: PropTypes.string
    }),
    onCardSelected: PropTypes.func
}

export default CarouselCard;