import React from "react";
import SHARE_ICON from "./assets/share.svg";
import ADD_ICON from "./assets/add.svg";
import "./instructionsModal.css"

function InstructionsModal(){
    return (
        <ol className="InstructionsModal">
            <li> Tap on the "Share Icon" <img src={SHARE_ICON} alt="share logo" /> </li>
            <li> Tap "Add to Home Screen" <img src={ADD_ICON} alt="add logo" /> </li>
            <li> Tap "Add" </li>
        </ol>
        
    )
}

export default InstructionsModal