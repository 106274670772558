import React, { useEffect } from "react"
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { ReactComponent as MoodImage } from "./assets/bipolar.svg";
import winObj from '../../shared/utils/window';
import "./index.css";

/**
 * Getting started is a container screen that acts as the landing page for the unauth user.
 * This component is wrapped with a store connector
 */
function GettingStarted({dispatch}){

    useEffect(()=>{
        dispatch(updateISHeader(false, true))
    }, [dispatch])

    /* Event Handlers */
    function openSignUpScreen(e){
        winObj.location.href = "/#/SignUp";
    }

    function openLoginScreen(e){
        winObj.location.href = "/#/Login";
    }

    return (
        <div className="GettingStarted">
            <h2> We are Inward Strong </h2>
            <h4> Are you ready to Join us? </h4>
            <MoodImage />
            <div className="ButtonGroup">
                <ISButton onClick={openSignUpScreen} value="Sign Up" />
                <ISButton onClick={openLoginScreen} value="I already have an account" type="clear" />
            </div>
        </div>
    )
}

export default StoreConnect(GettingStarted)