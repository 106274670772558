import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ButtonConstants from "./constant"
import "./button.css"

function ISButton(props){

    const [ buttonStyle, setButtonStyle ] = useState(props.type)

    useEffect(()=>{
        let styleType = props.type === ButtonConstants.TYPES.clear ? ButtonConstants.TYPES.clear : ButtonConstants.TYPES.filled;
        setButtonStyle( "IS-Button " + styleType)
    }, [props.type])

    /*Event Handlers*/
    function onButtonClick(e){
        e.preventDefault()
        if( props.onClick ) props.onClick(e)
    }

    return (
        <input disabled={props.disabled} onClick={onButtonClick} type="button" className={buttonStyle} value={props.value} style={props.size} />
    )
}

ISButton.propTypes = {
    value: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string
    }),
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

ISButton.defaultProps = {
    value: "",
    type: "filled",
    size: { width: "337px", height: "57px" },
    disabled: false
}

export default ISButton