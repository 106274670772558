import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import "./index.css"

function RadialProgressBar( { progressPercent, className, children, barColour } ){

    const [ progress, setProgress ] = useState(0);
    const [ progressBarColour, setProgressBarColour ] = useState("DarkSlateBlue");

    useEffect(() => {
        for ( let i = 0; i <= progressPercent; i++ ){
            setProgress(i);
        }
    }, [ progressPercent ])

    useEffect(() => {
        setProgressBarColour(barColour)
    }, [ barColour, setProgressBarColour ])

    return (
        <div className={ "RadialProgressBar " + className}>
            <CircularProgressbarWithChildren value={progress} styles={buildStyles({ pathColor: progressBarColour})} >
                { children }
            </CircularProgressbarWithChildren>
        </div>
    )

}

RadialProgressBar.propTypes = {
    progressPercent: PropTypes.number,
    className: PropTypes.string,
    barColour: PropTypes.string
}

RadialProgressBar.defaultProps = {
    progressPercent: 50,
    className: "",
    barColour: "#007AFF"
}

export default RadialProgressBar;