import React, { useEffect, useRef, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { createMessageModal } from "../../shared/utils/modals";
import ERRORS from "../../app/constant";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import SUCCESS_IMAGE_ICON from "../../app/assets/checkbox-circle-line.svg";
import "./index.css";

var { AUTH_ERRORS } = ERRORS;

const ACTION_CODE_SETTINGS = {
    url: "https://inwardstrong-e017e.web.app/",
    handleCodeInApp: true
  };

function ForgotPassword({dispatch}){

    const email = useRef();
    const [buttonState, setButtonState] = useState(true);

    useEffect(()=>{
        dispatch(updateISHeader(true, false))
    },[dispatch])

    const onEmailChange = ()=>{
        setButtonState(email.current.value.length === 0)
    }

    const onSubmit = ()=>{
        
        dispatch({ type: 'LOADER', data: { play: true } });

        sendPasswordResetEmail( getAuth(), email.current.value, ACTION_CODE_SETTINGS )
            .then((data)=>{
                let message = "If we find an account associated with this email, we’ll send you a link to reset your password so you can get back to working on your mental wellness and/or health.";
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Your request was submitted", SUCCESS_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, message ) } })
            })
            .catch((error)=>{
                let errMessage = AUTH_ERRORS.FORGOT_PASSWORD_ERRORS[error.code];
                if ( !errMessage ) errMessage = "Something went wrong. Please check the email address - " + error.code
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "An error has occured!", FAILURE_IMAGE_ICON, "Retry", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, errMessage ) } })
            })
            .finally(()=>{
                dispatch({ type: 'LOADER', data: { play: false } });
            })

    }

    return(
        <div className="ForgotPassword-Container">

            <h2> Welcome, </h2>
            <h4> Oops! Forgot your password? </h4>

            <div className="UserName InputField">
                <p> Enter your email </p>
                <input type="text" ref={email} id="username" name="username" onChange={onEmailChange} />
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Submit" onClick={onSubmit} /> 
            </div>

        </div>
    )
}

export default StoreConnect(ForgotPassword)