import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth"
import { useLocation } from "react-router";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import VideoPlayer from "../../components/videoPlayer";
import ISCarousel from "../../components/ISCarousel";
import Favourite from "../../components/favourite";
import NoteCard from "../../components/noteCard";
import { updateISHeader, createSkipModuleAction } from "../../shared/utils/dispatchHelpers";
import awardPoints from "../../shared/utils/rewards";
import { getSessionByID, getISUserProfile, updateMyCurrentSession, rearrangeMyCarepathway, updateMyCarePathway, getAllArticles, bookmarkSessionsChapter, getBookmarkedSessionsChapter, personalizeChapterStates, startSession, getMyCarePathway, isItemFavourited, addToFavourites, removeFromFavourites, CONTENT_TYPES, setCurrentChapterPlaybackSeekPosition, getCurrentChapterVideoSeekPos } from "../../shared/middleware";
import winObj from "../../shared/utils/window";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import "./index.css";

function SessionDetails({ dispatch, match, store }){

    const { sessionID, programSlug } = match.params
    const [ auth ] = useState(getAuth())
    const [ userProfile, setUserProfile ] = useState(null);
    const [ session, setSession ] = useState(null);
    const [ currentChapter, setCurrentChapter ] = useState(null)
    const [ chapters, setChapters ] = useState(null)
    const [ tools, setTools ] = useState(null)
    const [ initialChapterIndex, setInitialChapterIndex ] = useState(0);
    const [ chapterInFavs, setChapterInFavs ] = useState(false);
    const [ toolInsert, setToolInsert ] = useState(null);
    const [ videoSeekPos, setVideoSeekPos ] = useState(0);
    const { search } = useLocation();

    useEffect(()=>{
        dispatch(updateISHeader(true, null))
    },[dispatch])

    useEffect(() =>{

        if ( sessionID && auth.currentUser ) {

            if (winObj.document) winObj.document.title = "InwardStrong - Session"

            Promise.all([ getISUserProfile( auth.currentUser.uid ), getSessionByID(sessionID) ])
                .then((values) => {
                    let [ userProfileSnapshot, session ] = values
                    let ISUserProfile = userProfileSnapshot.val()

                    setUserProfile(ISUserProfile);
                    setSession(session);

                    const skipAction = session.isLocked ? null : createSkipModuleAction(dispatch, session, getMyCarePathway(ISUserProfile, programSlug), ISUserProfile, programSlug);
                    dispatch(updateISHeader(true, null, skipAction))


                    const urlParams = new URLSearchParams(search);
                    const selectedChapter = (urlParams.has("chapter")) ? session.chapters.find( ( chapter ) => chapter.id === urlParams.get("chapter") ) : null;

                    if ( selectedChapter )
                        setCurrentChapter( selectedChapter );
                    else {
                        setCurrentChapter( getBookmarkedSessionsChapter( ISUserProfile, session, programSlug ) )
                        setVideoSeekPos( getCurrentChapterVideoSeekPos( ISUserProfile, session, programSlug ) )
                    }

                    getAllArticles( [ session.title.toLowerCase().replace(" ", "") ] )
                        .then((articles) => {
                            setTools( articles.length > 0 ? articles.map(convertArticleIntoCarouselModel) : [] )
                        })

                    logAnalyticEvent(Events.SELECT_CONTENT, createEventData({ content_type: "Session", item_id: session.title, sponsoring_org: ISUserProfile.orgSponsor, program_name: programSlug }));

                })
                .catch(() => {
                    console.log("API calls have failed");
                })
        }
    
    // eslint-disable-next-line
    }, [ sessionID, auth, search ])


    useEffect(()=>{

        if ( session && currentChapter ) {
            logAnalyticEvent(Events.CHAPTER_STARTED, createEventData({ session_name: session.title, chapter_name: currentChapter.title, sponsoring_org: userProfile.orgSponsor, program_name: programSlug }));

            setChapters( personalizeChapterStates(session.chapters, currentChapter) );
            bookmarkSessionsChapter( userProfile, session, currentChapter, programSlug )
            setInitialChapterIndex( session.chapters.findIndex( chapter => (chapter.id === currentChapter.id) ))
            setChapterInFavs( isItemFavourited( userProfile, CONTENT_TYPES.SESSION, { sessionID: session.id, chapterID: currentChapter.id } ) )
            setToolInsert(null);
        }

        // eslint-disable-next-line
    }, [ currentChapter, session ])


    const onPlaybackEventHandle = (event) => {

        if ( event.type === 'timeupdate' && currentChapter.toolInserts ) {
            
            let curPos = Math.floor( event.data.seconds );
            if (currentChapter.toolInserts[ curPos ]) {
                setCurrentChapterPlaybackSeekPosition( userProfile, session, currentChapter, programSlug, curPos );
                setToolInsert( currentChapter.toolInserts[ curPos ] );
            }

        }

        if ( event.type === 'ended' ){

            const updatedtokens = awardPoints( userProfile.id, 'CHAPTER_VIEW_COMPLETE', store.tokens, userProfile.orgSponsor );
            dispatch( { type: 'TOKENS', data: updatedtokens } )

            let currentChapterIndex = session.chapters.findIndex((chapter)=> chapter.id === currentChapter.id)

            if ( currentChapterIndex > -1 ){
                if (currentChapterIndex !== (session.chapters.length - 1)) { 
                    setCurrentChapter( session.chapters[ currentChapterIndex + 1 ] )
                } else {
                    const currentCarePathway = userProfile.carePathway;
                    const modCarePathway = rearrangeMyCarepathway(session, 'finished', currentCarePathway);

                    updateMyCarePathway( userProfile.id, modCarePathway, programSlug)
                        .then(() => updateMyCurrentSession( userProfile.id, modCarePathway[1], programSlug ))
                        .then(()=>startSession( userProfile, { slug: modCarePathway[1] }, programSlug ))
                        .finally(()=>{
                            winObj.location.href = "/#/Home"
                        })
                }
            }

        }

    }

    const onChapterSelected = ( selectedChapter ) => {
        setCurrentChapter(selectedChapter);
    }

    const onFavouriteSelected = ( isSet, e ) => {

        e.stopPropagation();
       setChapterInFavs( isSet );
        let fav = ( isSet ) ? addToFavourites( userProfile, CONTENT_TYPES.SESSION, { sessionID: session.id, chapterID: currentChapter.id, programSlug }  ) : removeFromFavourites( userProfile, CONTENT_TYPES.SESSION, { sessionID: session.id, chapterID: currentChapter.id } )

        fav.then(( userProfile ) => {
            setUserProfile( userProfile );
            dispatch({type:'USER', data: userProfile})
        })

    }

    return (
        <div className="Session-Container">
            { (session && currentChapter ) ? 
                <>
                    <div className="Chapter-Details">
                        <h2> { session.title } </h2>

                        <h4> { currentChapter.title } </h4>

                        <div className="video-container">
                            <VideoPlayer src={currentChapter.videoSource} autoPlay={false} displayControls={true} seekPos={videoSeekPos} onPlaybackEvent={onPlaybackEventHandle} />
                        </div>

                        <div className="actionBtns">
                            <Favourite set={chapterInFavs} onClick={onFavouriteSelected} />
                        </div>

                       { toolInsert ? <NoteCard note={toolInsert.note} link={toolInsert.link} /> : null }

                        <div className="details">
                            <p> { currentChapter.description } </p>
                        </div>
                    </div>

                    { /** Audio Element */ }

                    { chapters ? <ISCarousel title="Session Chapters" cards={chapters} onCardSelected={onChapterSelected} initialSlide={initialChapterIndex} /> : null }

                    { tools ? <ISCarousel title="Tools & Techniques" cards={tools} /> : null }

                </>

            : null }

        </div>
    )

}

export default StoreConnect(SessionDetails);

function convertArticleIntoCarouselModel( article ) {
    return {
        title: article.title,
        link: "/#/Article/" + article.id,
        artwork: article.artwork,
        isLocked: false,
        flagText: ""
    }
}