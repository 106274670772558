import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ onScrollReset }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if ( onScrollReset ) onScrollReset( pathname );

  // eslint-disable-next-line
  }, [pathname]);

  return (null);
}

export default ScrollToTop;