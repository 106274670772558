
export default Object.freeze({
    AUTH_ERRORS: {
        REDIRECT_ERRORS: {
            "auth/account-exists-with-different-credential": "Account already exists. Please sign-in with the right credentials",
            "auth/web-storage-unsupported": "Please sign in regular browser mode"
        },
        CREATE_ACCOUNT_ERRORS: {
            "auth/account-exists-with-different-credential": "Account already exists. Please sign-in with the right credentials",
            "auth/email-already-in-use": "This email is already in use. Please use a different email account",
            "auth/invalid-email": "The provided email is invalid. Please check again",
            "auth/password-not-match": "The provided passwords do not match. Please check again",
            "auth/empty-value": "Email or passwords cannot be left empty"
        },
        LOGIN_ERRORS: {
            "auth/requires-recent-login": "You have used an old password. Please use your latest password",
            "auth/wrong-password": "The password provided is incorrect. Please try again",
            "auth/invalid-email": "Your account does not exist. Please create a new account"   
        },
        FORGOT_PASSWORD_ERRORS: {
            "auth/user-not-found": "We could not find an account associated with this email. Try using another email or create a new account"
        }
    },
    CAREPATHWAY_DEFAULT_ORDER: [ "stress", "anxiety", "relationships", "trauma" ],
    CAREPATHWAY_INTRO_MODULES: [ "introduction", "wellness-foundation" ],
    CAREPATHWAY_SPECIAL_MODULES: [ "decoding-teen-relationships" ],
    CAREPATHWAY_EVALUATION_WEIGHTS: {
        "stress": [0, 2, 3, 5, 6, 7 ],
        "relationships": [ 1, 4, 8 ],
        "trauma": [1, 2, 3, 6, 7, 9 ],
        "anxiety": [0, 2, 3, 4, 5, 6, 7 ]
    }
})