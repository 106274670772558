import React, { useEffect, useState } from "react";
import ScoreCard from "../../components/scoreCard";
import RadialProgressBar from "../../components/RadialProgressBar";
import winObj from "../../shared/utils/window";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { getCompanyTokens, getOrganizationByID } from "../../shared/middleware";
import { logAutoEvent } from "../../shared/utils/analytics";
import "./index.css"

const YEARLY_GOAL_TARGET = 104;
const MONTHLY_GOAL_TARGET = 8;

function Tokens( { dispatch, store } ){

    const [ pointsCard, setPointsCard ] = useState(null);
    const [ tokenType, setTokenType ] = useState("month");
    const [ noOfFreeAccountsGiven, setNoOfFreeAccountsGiven ] = useState(0);
    const [ progressPercent, setProgressPercent ] = useState(0);
    const [ remainingPoints, setRemainingPoints ] = useState(0);

    const [ companyTokens, setCompanyTokens ] = useState(0);
    const [ companyTokenType, setCompanyTokenType ] = useState("month");
    const [ companyPointsCard, setCompanyPointsCard ] = useState(null);
    const [ noOfFreeAccountsGivenToCompany, setNoOfFreeAccountsGivenToCompany ] = useState(0);
    const [ companyProgressPercent, setCompanyProgressPercent ] = useState(0);
    const [ companyRemainingPoints, setCompanyRemainingPoints ] = useState(0);
    const [ companyTokensBlurb, setCompanyTokensBlurb ] = useState("Please wait, while we calculate the total earned tokens in your organization")

    useEffect(()=>{
        dispatch(updateISHeader(true, null))
    },[dispatch])

    useEffect(() => {

        if (winObj.document) winObj.document.title = "InwardStrong - Tokens"

        if ( store.tokens ) {
            
            if ( store.tokens >= YEARLY_GOAL_TARGET ) {
                setTokenType("year")
                setNoOfFreeAccountsGiven( Math.floor( store.tokens / YEARLY_GOAL_TARGET ) );

                let modValue = ( store.tokens % YEARLY_GOAL_TARGET )
                setProgressPercent(modValue / YEARLY_GOAL_TARGET * 100 );
                setRemainingPoints( YEARLY_GOAL_TARGET - modValue );
            } else { 
                setTokenType("month");
                setNoOfFreeAccountsGiven( Math.floor( store.tokens / MONTHLY_GOAL_TARGET ) );

                let modValue = ( store.tokens % MONTHLY_GOAL_TARGET )

                setProgressPercent((modValue / MONTHLY_GOAL_TARGET ) * 100 );
                setRemainingPoints( MONTHLY_GOAL_TARGET - modValue );
            }
        }

        if ( store.userProfile ) {

            Promise.all([ getOrganizationByID( store.userProfile.orgSponsor ), getCompanyTokens( store.userProfile.orgSponsor )])
                .then((values) => {

                    let [ orgSnapshot, companyTokenresponse ] = values
                    let org = orgSnapshot.val()
                    let baseTokenCount = ( org.baseEarnedTokens ) ? org.baseEarnedTokens : 0;

                    if ( companyTokenresponse && companyTokenresponse.length > 0 ) {
                        setCompanyTokens( parseInt(companyTokenresponse[0]["customEvent:company_token_value"]) + parseInt(baseTokenCount) );
                        setCompanyTokensBlurb("Your tokens are being pooled with others within your organization to make a bigger impact! Pooled tokens tallied every 24 hours")
                    }

                })
                .catch( (err) =>{
                    console.log("Kaput! ", err)
                })

        }

        logAutoEvent();
    
    // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if ( noOfFreeAccountsGiven && tokenType ){
            setPointsCard({
                date: tokenType === "month" ? "Monthly Subscription Earned" : "Yearly Subscription Earned",
                firstScore: noOfFreeAccountsGiven,
                firstScoreLabel: "more can become Inward Strong because of you",
            })
        }

    }, [ noOfFreeAccountsGiven, tokenType ])


    useEffect(() => {
        if ( companyTokens > 0 ) {

            if ( companyTokens >= YEARLY_GOAL_TARGET ) {
                setCompanyTokenType("year")
                setNoOfFreeAccountsGivenToCompany( Math.floor( companyTokens / YEARLY_GOAL_TARGET ) );

                let modValue = ( companyTokens % YEARLY_GOAL_TARGET )
                setCompanyProgressPercent(modValue / YEARLY_GOAL_TARGET * 100 );
                setCompanyRemainingPoints( YEARLY_GOAL_TARGET - modValue );
            } else { 
                setCompanyTokenType("month");
                setNoOfFreeAccountsGivenToCompany( Math.floor( companyTokens / MONTHLY_GOAL_TARGET ) );

                let modValue = ( companyTokens % MONTHLY_GOAL_TARGET )

                setCompanyProgressPercent((modValue / MONTHLY_GOAL_TARGET ) * 100 );
                setCompanyRemainingPoints( MONTHLY_GOAL_TARGET - modValue );
            }

        }

    // eslint-disable-next-line
    }, [ companyTokens ])

    useEffect(() => {

        if ( noOfFreeAccountsGivenToCompany && companyTokenType ){
            setCompanyPointsCard({
                date: companyTokenType === "month" ? "Monthly Subscription Earned" : "Yearly Subscription Earned",
                firstScore: noOfFreeAccountsGivenToCompany,
                firstScoreLabel: "more can become Inward Strong because of your organization",
            })
        }

    }, [ noOfFreeAccountsGivenToCompany, companyTokenType ])


    return (
        <div className="TokensContainer">
            <h2> Tokens of Gratitude&trade; </h2>

            <div className="Content">
                <h4> My Tokens </h4>
                <p>
                    Earn tokens each time you complete a Stress Check-In, Mood Tracker, Tool or Technique, Mindful Minute, or a Session Chapter.
                    <br/> <br />
                </p>
            </div>

            <RadialProgressBar className="TokensProgressBar" progressPercent={progressPercent} barColour="#007AFF" >
                <div className="InsideCard Blue">
                    <h4> CURRENT BALANCE </h4>
                    <div className="MainHeading"> 
                        <h3> { store.tokens } </h3>
                    </div>
                    <p className="blurb"> { remainingPoints } token(s) needed </p>
                </div>
            </RadialProgressBar>

            <div className="Content">
                <p>
                    <i> 96 Tokens will gift a year of mental health support to someone in need. </i> 
                </p>
            </div>

            { pointsCard ?
                 <ScoreCard card={pointsCard} />
            : null }


            <div className="Content Collective">
                <h4> Collective Tokens </h4>
                <p> 
                    { companyTokensBlurb }
                    <br/> <br />
                </p>
            </div>

            { companyPointsCard ? 
                <>
                    <RadialProgressBar className="TokensProgressBar" progressPercent={companyProgressPercent} barColour="#FAAC45" >
                        <div className="InsideCard Pumpkin">
                            <h4> CURRENT BALANCE </h4>
                            <div className="MainHeading"> 
                                <h3> { companyTokens } </h3>
                            </div>
                            <p className="blurb"> { companyRemainingPoints } token(s) needed </p>
                        </div>
                    </RadialProgressBar>
                
                    <ScoreCard card={companyPointsCard} /> 
                </>
            : null }

        </div>
    )
}

export default StoreConnect(Tokens)