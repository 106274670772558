import React, { useEffect, useState } from "react";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISCarousel from "../../components/ISCarousel";
import FilterChips from "../../components/filterChips";
import Favourite from "../../components/favourite";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { getArticlesByID, getSessionsByID, removeFromFavourites, CONTENT_TYPES } from "../../shared/middleware";
import winObj from "../../shared/utils/window";
import "./index.css";

function FavouritesScreen({ dispatch, store }){

    const [ filters, setFilters ] = useState([]);
    const [ sessions, setSessions ] = useState([]);
    const [ favChapters, setFavChapters ] = useState([]);
    const [ favArticles, setFavArticles ] = useState([]);

    useEffect(() => {
        dispatch( updateISHeader(true, null, null) )
    }, [ dispatch ])

    useEffect(() => {        
        fetchFavItems( store?.userProfile?.favourites )
    //eslint-disable-next-line
    },[ ])

    useEffect(() => {
        fetchFavItems( store?.userProfile?.favourites )
    }, [ store.userProfile.favourites ] )

    useEffect(() => {

        let favourites = store.userProfile.favourites

        if ( sessions && sessions.length > 0 && favourites.session) {

            let chapters = []

            sessions.forEach( (session) => {
                favourites.session[ session.id ].forEach( ( chapterIDSlug ) => {

                    let [ chapterID, programSlug ] = chapterIDSlug.split(":");
                    let chapter = session.chapters.find( chapter => chapter.id === chapterID);
                    chapter.programSlug = programSlug;
                    chapter.sessionID = session.id;

                    if ( chapter ) chapters.push( chapter );
                })
            })

            setFavChapters( chapters );

        }
    
    //eslint-disable-next-line
    }, [ sessions ])

    const onCardSelected = (chapter) => {
        if ( chapter.programSlug ) winObj.location.href = "/#/Session/" + chapter.programSlug + "/" + chapter.sessionID + "?chapter=" + chapter.id;
    }

    const onFiltersSelected = ( sessions ) => {

        let filteredSessions = sessions.filter( (session) => session.isSelected )
        filteredSessions.length > 0 ? setSessions(sessions.filter( (session) => session.isSelected )) : setSessions( sessions );
    }

    const onChapterFavouriteSelected = ( set, selectedChapter ) => {
        
        if ( !set ) {

            let session = sessions.find( ( session ) => {
                let chap = session.chapters.find( ( chapter ) => chapter && chapter.id === selectedChapter.id )
                return ( chap ) ? true : false;
            })

            removeFromFavourites( store.userProfile, CONTENT_TYPES.SESSION, { sessionID: session.id, chapterID: selectedChapter.id } )
                .then(( userProfile ) => {
                    dispatch({type:'USER', data: userProfile})
                    // setFetchFavs( true );
                })
        }

    }


    function fetchFavItems( userFavourites ){
        if ( userFavourites ){
            let favourites = userFavourites

            if ( favourites.session ) {

                let sessionIDs = Object.entries( favourites.session ).filter( ( [ key, value ] ) => key && value.length > 0).map( ( [ key ] ) => key )
                
                if ( sessionIDs.length > 0 ) {
                    getSessionsByID( sessionIDs )
                        .then( ( sessions ) => {
                            setFilters( sessions.map(convertSessionToFilter) );
                            setSessions( sessions );
                        })
                }

            }

            // Fetch all articles
            if ( favourites.article && favourites.article.length > 0 ) {
                getArticlesByID( favourites.article )
                    .then((articles) => {
                        setFavArticles( articles.map( convertArticleIntoCarouselModel ) )
                    })
            }
        }
    }


    return (
        <div className="Favourites-Container">
            <h2> Favourites </h2>

            {  favChapters && favChapters.length > 0 ? 
                <>
                    { filters.length > 0 ?
                            <div className="Tags-List">
                                <FilterChips title="" filters={filters} onChipsSelected={onFiltersSelected} />
                            </div> 
                    : null }

                    { favChapters ?
                        <div className="FavChaptersList">

                            <h3> Session Chapters </h3>

                            { favChapters.map( ( chapter ) => 
                                <div className="FavChapterListItem" key={chapter.id} onClick={() => { onCardSelected(chapter) }} >
                                    <img src={chapter.artwork} alt="artwork" />
                                    <p> { chapter.title } </p>
                                    <Favourite set={true} onClick={(set, e) =>  { e.stopPropagation(); onChapterFavouriteSelected( set, chapter ) }} />
                                </div>
                            )}
                        </div>
                    : null
                    }
                </>
                : null
            }   

            { favArticles && favArticles.length > 0 ? <ISCarousel title="Tools & Techniques" cards={favArticles} /> : null }

            { favChapters.length === 0 && favArticles.length === 0 ?
                <p> You have not added any favourite chapters or articles to your collection </p>
                : null
            }

        </div>
    )
}

export default StoreConnect(FavouritesScreen);

/* Helpers */

function convertArticleIntoCarouselModel( article ) {
    return {
        title: article.title,
        link: "/#/Article/" + article.id,
        artwork: article.artwork,
        isLocked: false,
        flagText: ""
    }
}


function convertSessionToFilter( session ){
    return { ...session, name: session.title }
}