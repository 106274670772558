import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";

// This list of functions act as a gateway to GA suite.

/**
 * Logs an event to the data repo
 * @param {String} eventName 
 * @param {Object} props 
 */
export function logAnalyticEvent(eventName, props){

    if ( eventName === "user_settings" ) {
        setUserProperties( getAnalytics(), props );
    } else {
        logEvent( getAnalytics(), eventName, props );
    }
}

/**
 * Set's the user ID if available for a user within all analytics call
 * @param {String} userID
 */
export function setAnalyticUserID( userID ){
    setUserId( getAnalytics(), userID );
}

/**
 * Log all automatic events set up by Google Analytics
 */
export function logAutoEvent(){
    getAnalytics();
}

/**
 * Creates a data object to be sent along to the GA data lake
 * @param {Object} props 
 */
export function createEventData( props ){
    let data = {
        ...props,
        app_version: process.env.REACT_APP_VERSION 
    }

    if ( data.sponsoring_org ) {
        data = {
            ...data,
            sponsoring_org: data.sponsoring_org + "-id"
        }
    }

    return data;
}


export default Object.freeze({
    SELECT_CONTENT: "select_content",
    USER_SETTINGS: "user_settings",
    SIGN_UP: "sign_up",
    LOGIN: "login",
    WELLNESS_GOALS: "wellness_goals",
    CAREPATHWAY_TYPE: "carepathway_type",
    QUIZ_BEGIN: "tutorial_begin",
    QUIZ_COMPLETE: "tutorial_complete",
    QUIZ_SKIP: "quiz_skip",
    CHAPTER_STARTED: "chapter_started",
    STRESS_SCALE_LOG: "stress_scale_log",
    SUGGESTED_FOCUS_AREAS: "suggested_focus_areas",
    SKIP_SPONSOR: "skip_sponsor",
    COUPON_SET: "coupon_set",
    COUPON_REMOVED: "coupon_removed",
    ORG_TOKEN: "org_token",
    PWA_INSTALL: "pwa_installed"
});