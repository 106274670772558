import React from "react";
import PropTypes from "prop-types"
import SAMPLE from "../../app/assets/sample.png";
import winObj from "../../shared/utils/window";
import "./index.css";

function ArticleListItem( { article } ){

    const onArticleClick = () => {
        winObj.location.href = "/#/Article/" + article.id;
    }

    return (
        <div className="Tool" onClick={onArticleClick} >
            <img className="artwork" src={article.artwork} alt="IMG" />
            <div className="metadata">

                <p className="title"> { article.title } </p>

                <p className="description"> { article.description } </p>

            </div>
        </div>
    )
}

ArticleListItem.propTypes = {
    article: PropTypes.shape({
        artwork: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.string,
        referenceLink: PropTypes.string,
        id: PropTypes.string
    })
}

ArticleListItem.defaultProps = {
    article: {
        artwork: SAMPLE,
        title: "Pushed by Pain, Pulled by Intention",
        description: "Create a plan towards transforming yourself.Use this process to assist you in rebalancing your",
        link: "",
        referenceLink: ""
    }
}

export default ArticleListItem
