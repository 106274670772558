import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getAllOrganizations, getISUserProfile } from "../../shared/middleware";
import logo from "./assets/is-logo.png";
import './logo.css';

/**
 * Simple component that displays the logo of inward strong
 * The logo can be override and various width and height for it could be configured
 */
function Logo({displayLogo, user, fetchLogo }){

    const [ logoURL, setLogoURL ] = useState(null);

    useEffect(()=>{

        if ( (user && user.uid) || fetchLogo ) {

            Promise.all([ getISUserProfile( user.uid ), getAllOrganizations() ])
                .then((values) =>{
                    let [ userProfileSnapshot, organizationsSnapshot ] = values
                    let ISUserProfile = userProfileSnapshot.val();
                    let organizations = organizationsSnapshot.val();

                    if ( ISUserProfile.orgSponsor ) {
                        let sponsor = organizations.find((org) => {
                            return ( org && org.id && org.id.toString() === ISUserProfile.orgSponsor.toString() )
                        })

                        if ( sponsor && sponsor.logo ) setLogoURL(sponsor.logo)
                        else setLogoURL(logo);

                    } else {
                        setLogoURL(logo);
                    }

                })
                .catch((error)=>{
                    console.log(" API call for logo has failed ", error);
                    setLogoURL(logo);
                })
        } else {
            setLogoURL(logo);
        }

    },[ user, fetchLogo ])

    return(
        <>
           { displayLogo && logoURL ? <img src={logoURL} className="logo" alt="Logo" /> : null }
        </>
    )
}

Logo.propTypes = {
    displayLogo: PropTypes.bool,
    size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
    }),
    user: PropTypes.any,
    fetchLogo: PropTypes.bool
}

Logo.defaultProps = {
    displayLogo: true,
    fetchLogo: false
}

export default Logo;