import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, FacebookAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ImageButton from "../../components/ImageButton";
import { saveToLocalStorage, getFromLocalStorage } from "../../shared/utils/localStorage";
import { updateISHeader, createLoginAction } from "../../shared/utils/dispatchHelpers";
import GoogleLogo from "./assets/google-logo.png";
import FacebookLogo from "./assets/facebook-logo.png";
import "./index.css"

function SignUp({ dispatch }){

    const checkBoxRef = useRef();

    const [googleProvider, setGoogleProvider] = useState(null);
    const [fbProvider, setFBProvider] = useState(null);
    const [buttonState, setButtonState] = useState(true);

    useEffect(()=>{

        let gProvider = new GoogleAuthProvider()
        gProvider.setCustomParameters({
            prompt: 'select_account'
          });

        setGoogleProvider(gProvider);
        setFBProvider(new FacebookAuthProvider());
        dispatch(updateISHeader(true, false, createLoginAction()))
    }, [dispatch])

    /* Event Handlers */

    function onTextChange(e){
        saveToLocalStorage('displayName', e.target.value );
    }

    function onCheckBoxClick(e){
        setButtonState(!checkBoxRef.current.checked);
    }

    function createAccountWithFacebook(e){
        if( !getFromLocalStorage('displayName') )
            saveToLocalStorage('displayName', " " );
        
        signInWithRedirect(getAuth(), fbProvider);
    }

    function createAccountWithGoogle(e) {
        if( !getFromLocalStorage('displayName') )
            saveToLocalStorage('displayName', " " );
            
        signInWithRedirect(getAuth(), googleProvider);
    }

    return (
        <div className="SignUp-Container">

            <h2> Welcome, </h2>
            <h4> What should we call you? </h4>

            <div className="UserName InputField">
                <p> Enter your user name </p>
                <input type="text" id="username" name="username" onChange={onTextChange} placeholder="Alias" />
            </div>

            <div className="Terms">
                <input type="checkbox" id="terms" name="terms" ref={checkBoxRef} onClick={onCheckBoxClick} />
                <a href="https://www.inwardstrong.com/terms-of-use" target="_blank" rel="noreferrer" style={{position: "relative", bottom: "3px", left: "4px"}}> I agree to the Terms of Service </a>
            </div>

            <div className="AccountTypes">
                <p> Sign Up With </p> 
                <ImageButton disabled={buttonState} className="Google" logo={GoogleLogo} value="Continue with Google" onClick={createAccountWithGoogle} />
                <ImageButton disabled={buttonState} className="Facebook" logo={FacebookLogo} value="Continue with Facebook" onClick={createAccountWithFacebook} />
                <Link className="CreateAccount" to="/CreateAccount" > Or choose a password </Link>
            </div>

        </div>
    )

}

export default StoreConnect(SignUp)