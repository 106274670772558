import { getDatabase, ref, set, get, update, child } from "firebase/database";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import { getEntries, getEntry, getTags } from "./content";
import { parseItemsToSessions, createSessionModel, parseItemstoFilters, parseItemstoArticles, parseItemstoQuiz, createArticleModel, parseItemsToPrograms, parseGoogleAnalyticsResponse } from "./dataAdapters";
import APP_CONFIG from "../../app/constant";
const DEFAULT_PROGRAM_SLUG = "inward-strong";
const CONTENT_TYPES = {
    SESSION: "session",
    ARTICLE: "article"
}

/**
 * Creates a base user profile for a new user
 * @param {string} userID - Auth ID
 * @param {string} displayName - Display name for the given user
 */
function createISUserProfile( userID, displayName ){

    const db = getDatabase();

    return set(ref(db, 'Users/' + userID), {
        "id": userID,
        "displayName": displayName,
        "points": 0,
    });
}

/**
 * Update displayName for a user
 * @param {string} userID - Auth ID
 * @param {string} displayName - Display name for the given user
 */
 function updateISUserDisplayName( userID, displayName ){

    const db = getDatabase();

    return update(ref(db, 'Users/' + userID), {
        "displayName": displayName
    });
}

/**
 * Update displayStatus for a user
 * @param {string} userID - Auth ID
 * @param {string} status - status for the given user
 */
function updateISUserDisplayStatus( userID, displayStatus ) {
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID), {
        "displayStatus": displayStatus
    });
}

/**
 * get User's IS profile
 * @param { string } userID
 * @return { Promise } a firebase promise object
 */
 function getISUserProfile( userID ){

    const dbRef = ref(getDatabase());
    return get(child(dbRef, `Users/` + userID));
}

/**
 * List of all organizations
 * @return { Promise } a firebase promise object
 */
function getAllOrganizations(){

    const dbRef = ref(getDatabase());
    return get(child(dbRef, `Organizations`));
}

/**
 * Get the organization based on it's ID
 * @param {int} orgID Organization ID
 * @return {Promise} a firebase promoise object
 */
function getOrganizationByID( orgID ){
    const dbRef = ref(getDatabase());
    return get(child(dbRef, `Organizations/` + orgID));
}

/**
 * Returns the carePathway for the given user based on the current program
 * @param {Object} userProfile 
 * @param {String} programName 
 * @return {Array} CarePathway Array of string
 */
function getMyCarePathway( userProfile, programSlug ){

    if ( programSlug === DEFAULT_PROGRAM_SLUG ) return userProfile.carePathway
    return userProfile?.programs?.[programSlug]?.carePathway;
}

/**
 * Updates the org sponsor for the user
 * @param {string} userID - Auth ID
 * @param {string} sponsorID
 * @param {string} sponsor email ID
 */
 function updateOrgSponsorForUser( userID, sponsorID, sponsorEmailID ){

    const db = getDatabase();

    return update(ref(db, 'Users/' + userID), {
        "orgSponsor": sponsorID,
        "sponsorEmail": sponsorEmailID
    });
}

/**
 * Updates the org sponsor for the user
 * @param {string} userID - Auth ID
 * @param {string} sessionSlug - Session Slug name
 * @param {string} programSlug the slug of the program name
 */
function updateMyCurrentSession( userID, sessionSlug, programSlug ){

    const db = getDatabase();

    let path = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? 'Users/' + userID : 'Users/' + userID +'/programs/' + programSlug

    return update(ref(db, path), {
        "currentModule": sessionSlug
    });
}

/** Start's a new session for the given user
 * @param { object } userProfile object
 * @param { object } session or module object
 * @param { string } programSlug program slug
 * @return { Promise }
 */
function startSession( userProfile, session, programSlug ){
    const db = getDatabase();

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;
    if ( !startedModules ) startedModules = {}

    startedModules[ session.slug ] = { "slug": session.slug, "currentChapter": session.chapters?.length > 0 ? session.chapters[0].id : "" }

    let path = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? 'Users/' + userProfile.id : 'Users/' + userProfile.id  +'/programs/' + programSlug

    return update(ref(db, path), {
        "startedModules": startedModules
    });
}


/**
 * A chpater of a session can be bookmarked, so a user can resume from where they left off.
 * @param {object} userProfile
 * @param {object} session - session object to be bookmarked
 * @param {object} chapter - chapter object to be bookmarked
 * @param { string } programSlug program slug
 * @return {Promise}
 */
function bookmarkSessionsChapter( userProfile, session, chapter, programSlug ){
    const db = getDatabase();

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;
    if ( !startedModules ) startedModules = {}

    startedModules[ session.slug ] = { "name": session.slug, "currentChapter": chapter.id }

    let path = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? 'Users/' + userProfile.id : 'Users/' + userProfile.id  +'/programs/' + programSlug

    return update(ref(db, path), {
        "startedModules": startedModules
    });
}

/**
 * Returns the bookmarked chapter if present, else returns the first chapter in the session
 * @param {Object} startedSessions object
 * @param {Object} currentSession object
 * @param { string } programSlug program slug
 * @return {String} currentChapter or first chapter
 */
function getBookmarkedSessionsChapter( userProfile, currentSession, programSlug ){

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;

    if ( startedModules && startedModules[currentSession.slug] ) {
        const bookmarkedSession = startedModules[currentSession.slug]
        const bookmarkedChapter = currentSession.chapters.find((chapter) => bookmarkedSession.currentChapter === chapter.id )
        if ( bookmarkedChapter ) return bookmarkedChapter;
    }

    return currentSession.chapters[0]
}

/**
 * Returns the seek position for the current session if one exists
 * @param {Object} userProfile object
 * @param {Object} currentSession object
 * @param { string } programSlug program slug
 * @return {String} currentChapter or first chapter
 */
 function getCurrentChapterVideoSeekPos( userProfile, currentSession, programSlug ){

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;

    if ( startedModules && startedModules[currentSession.slug] ) {
        const bookmarkedSession = startedModules[currentSession.slug]
        return ( bookmarkedSession && bookmarkedSession.seekPos ) ? bookmarkedSession.seekPos : 0;
    }

    return 0;
}

/**
 * Mark the seek position for the current chapter, so it can be resumed
 * @param {object} userProfile
 * @param {object} session - session object to be bookmarked
 * @param {object} chapter - chapter object to be bookmarked
 * @param { string } programSlug program slug
 * @param {int} seek pos in seconds
 * @return {Promise}
 */
 function setCurrentChapterPlaybackSeekPosition( userProfile, session, chapter, programSlug, seekPos ){
    const db = getDatabase();

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;
    if ( !startedModules ) startedModules = {}

    startedModules[ session.slug ] = { "name": session.slug, "currentChapter": chapter.id, seekPos }

    let path = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? 'Users/' + userProfile.id : 'Users/' + userProfile.id  +'/programs/' + programSlug

    return update(ref(db, path), {
        "startedModules": startedModules
    });
}

/**
 * Updates quiz response for a user 
 * @param { string } userID
 * @param { Object } quizResponses
 */
function updateUsersQuizResponse( userID, quizResponses ){
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID), {
        "quizResponses": quizResponses,
    });
}

/**
 * Updates the carepathway order for the given user 
 * @param { string } userID
 * @param { Object } quizResponses
 * @param { string } programSlug
 */
 function updateMyCarePathway( userID, carePathwayOrder, programSlug ){
    const db = getDatabase();

    let path = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? 'Users/' + userID : 'Users/' + userID  +'/programs/' + programSlug

    return update(ref(db, path), {
        "carePathway": carePathwayOrder,
    });
}

/**
 * Add's a stress check-in for the given user
 * @param { String } userID
 * @param { String } dateFormat in string `yyyy-LL-dd:HH`
 * @param { Result } result object { type and score }
 * @return Promise
 */
function addStressCheckIn( userID, dateFormat, { type, score } ){
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID + '/stressCheckIn/' + dateFormat + "/" + type ), {
        score
    });
}

/**
 * Add the user's mood tracking scores to the backend
 * @param { String } userID 
 * @param { String } dateFormat in string `yyy-LL-dd:HH`
 * @param { Result } Assessment result { name, score }
 * @return Promise
 */
function addMoodTrackingScores( userID, dateFormat, { name, score } ){
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID + '/moodTrackingScores/' + dateFormat + "/" + name ), {
        score
    });
}

/**
 * Updates the rewards points for the user
 * @param {String} userID 
 * @param {Int} points Total Points for the user
 * @return {Promise}
 */
function updateRewardsPoints( userID, points ){
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID ), {
        "tokens": points
    });
}

/**
 * Updates the activeProgram value for a user
 * @param {String} userID
 * @param {String} programSlug
 */
function updateActiveProgram( userID, programSlug ) {
    const db = getDatabase();

    return update(ref(db, 'Users/' + userID ), {
        "activeProgram": programSlug
    });
}

/**
 * Returns the current active program for the user
 * @param {Object} userProfile
 * @return {String} slug active program slug
 */
function getActiveProgramName( userProfile ){
    return ( userProfile && userProfile.activeProgram ) ? userProfile.activeProgram : DEFAULT_PROGRAM_SLUG;
}

/**
 * Returns if the item is in favs collection or not
 * @param { Object } UserProfile
 * @param { String } ItemType
 * @param { Object } ContentID { artcileID, chapterID, sessionID }
 * @return { Boolean } true/false
 */
function isItemFavourited( userProfile, itemType, contentID ){

    let collection = [];
    let content = null;
    const { articleID, sessionID, chapterID } = contentID;
    let favourites = userProfile.favourites ? userProfile.favourites : {};

    if ( itemType === CONTENT_TYPES.SESSION ) {
        collection = ( favourites?.[itemType]?.[sessionID] ) ? favourites[itemType][sessionID] : []
        content = collection.find( ( favItemID ) => {
            let favItemSlugID = favItemID.split(":")
            return favItemSlugID[0] === chapterID 
        })
    } else {
        collection = ( favourites[itemType] ) ? favourites[itemType] : [];
        content = collection.find( ( favItemID ) => favItemID === articleID )
    }

    return content ? true : false;
}

/**
 * Add's the content to the user's favourite collection
 * @param { Object } userProfile
 * @param { String } ItemType
 * @param { Object } ContentID { artcileID, chapterID, sessionID }
 */
function addToFavourites( userProfile, itemType, contentID ){

    const db = getDatabase()
    let collection = []
    let updatedFavCollection = {}
    let path = 'Users/' + userProfile.id + '/favourites/'
    const { articleID, sessionID, chapterID, programSlug } = contentID;

    let favourites = userProfile.favourites ? userProfile.favourites : {};

    if (itemType === CONTENT_TYPES.SESSION) {
        collection = ( favourites?.[itemType]?.[sessionID] ) ? favourites[itemType][sessionID] : []
        collection.push( chapterID + ":" + programSlug );
        updatedFavCollection[sessionID] = collection;
        path = path + itemType
    } else {
        collection = ( favourites[itemType] ) ? favourites[itemType] : []
        collection.push( articleID )
        updatedFavCollection[ itemType ] = collection
    }

    return update(ref(db, path ), updatedFavCollection)
        .then( () => {
            return updateFavsToUserProfile( userProfile, itemType, updatedFavCollection )
        });
}

/**
 * Removes the content from the user's favourite collection
 * @param { Object } userProfile
 * @param { String } ItemType
 * @param { Object } ContentID { artcileID, chapterID, sessionID }
 */
function removeFromFavourites( userProfile, itemType, contentID ){

    const db = getDatabase()
    let collection = []
    let updatedFavCollection = {}
    let path = 'Users/' + userProfile.id + '/favourites/'
    const { articleID, sessionID, chapterID } = contentID;

    let favourites = userProfile.favourites ? userProfile.favourites : {};

    if ( itemType === CONTENT_TYPES.SESSION ) {
        collection = ( favourites?.[itemType]?.[sessionID] ) ? favourites[itemType][sessionID] : []
        collection = collection.filter(( favItemID ) => {
            let itemSlugID = favItemID.split(":")
            return itemSlugID[0] !== chapterID;
        });
        updatedFavCollection[sessionID] = collection;
        path = path + itemType
    } else {
        collection = ( favourites[itemType] ) ? favourites[itemType] : [];
        collection = collection.filter(( favItemID ) => favItemID !== articleID );
        updatedFavCollection[ itemType ] = collection
    }

    return update(ref(db, path ), updatedFavCollection)
        .then( () => {
            return updateFavsToUserProfile( userProfile, itemType, updatedFavCollection )
        });
}

/**
 * Returns a list of all available sessions for the user based on their carepathway order
 * TODO: Get user's care pathway answers to curate the session listing
 */
function getAllSessions(){

    let searchQuery = {
        content_type: "sessions"
    }

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemsToSessions( data?.items )
        })
        .catch(()=>{
            console.log("Data Botched");
        })
}

/**
 * Returns a list of all available programs 
 */
function getAllPrograms(){

    let searchQuery = {
        content_type: "program"
    }

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemsToPrograms( data.items )
        })
        .catch((err)=>{
            console.log("Data Botched", err);
        })
}

/**
 * Fetch a session by ID
 * @param { string } sessionID 
 * @return { Promise }
 */
function getSessionByID( sessionID ){
    return getEntry( sessionID )
                .then(( data )=>{
                    return createSessionModel( data );
                })
                .catch((error) =>{
                    console.log("Error - ", error);
                });
}

/**
 * Fetch a list of sessions based on the given ID's
 * @param { Array } ArticleID's in a list
 * @return { Promise }
 */
 function getSessionsByID( sessionIDList ){
    let searchQuery = {
        content_type: "sessions"
    }
    
    let IDlist = sessionIDList.length > 0 ? sessionIDList.reduce( (prevVal, curVal) => prevVal + "," + curVal ) : ""
    if ( IDlist !== "" ) searchQuery["sys.id[in]"] = IDlist;

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemsToSessions( data?.items )
        })
        .catch(()=>{
            console.log("Data Botched");
        })
}

/**
 * Fetch all filters defined by the platform
 * @return { Promise }
 */
function getFilters(){
    return getTags()
                .then((resp)=>{
                    return parseItemstoFilters( resp.items );
                })
}

/**
 * Get All Tools & Tech articles
 * @param { Array } filter id's as an array
 */
function getAllArticles( filters ){

    let searchQuery = {
        content_type: "tool"
    }
    
    let tags = filters.length > 0 ? filters.reduce( (prevVal, curVal) => prevVal + "," + curVal ) : ""
    if ( tags !== "" ) searchQuery["metadata.tags.sys.id[in]"] = tags;

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemstoArticles( data?.items )
        })
        .catch(()=>{
            console.log("Data Botched");
        })
}

/**
 * Fetch an article by ID
 * @param { string } articleID 
 * @return { Promise }
 */
 function getArticleByID( articleID ){
    return getEntry( articleID )
                .then(( data )=>{
                    return createArticleModel( data );
                })
                .catch((error) =>{
                    console.log("Error - ", error);
                });
}

/**
 * Fetch a list of articles based on the given ID's
 * @param { Array } ArticleID's in a list
 * @return { Promise }
 */
 function getArticlesByID( articleIDList ){
    let searchQuery = {
        content_type: "tool"
    }
    
    let IDlist = articleIDList.length > 0 ? articleIDList.reduce( (prevVal, curVal) => prevVal + "," + curVal ) : ""
    if ( IDlist !== "" ) searchQuery["sys.id[in]"] = IDlist;

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemstoArticles( data?.items )
        })
        .catch(()=>{
            console.log("Data Botched");
        })
}

/**
 * Get All Quiz objects
 */
function getAllQuiz(){

    let searchQuery = {
        content_type: "quiz"
    }

    return getEntries( searchQuery )
        .then(( data )=>{
            return parseItemstoQuiz( data?.items )
        })
        .catch(()=>{
            console.log("Data Botched");
        })

}

/**
 * Get Stress Check-in Objects
 */
 function getStressCheckinScales(){

    let searchQuery = {
        content_type: "stressCheckIn"
    }

    return getEntries( searchQuery )
        .then(( data )=>{
            return data?.items[0]?.fields;
        })
        .catch(()=>{
            console.log("Data Botched");
        })

}

/**
 * Get Mood Tracking Scales
 */
function getMoodTrackingScales(){

    let searchQuery = {
        content_type: "moodTrackingScale"
    }

    return getEntries( searchQuery )
        .then(( data )=>{
            return data?.items[0]?.fields;
        })
        .catch(()=>{
            console.log("Data Botched");
        })

}

/**
 * Retrieves the company tokens from the IS microservice
 * @param {string} Sponsoring company ID
 * @return{promise} object
 */
function getCompanyTokens( orgSponsor ){

    const url = ( process.env.NODE_ENV === 'production' ) ? "https://us-central1-inwardstrong-e017e.cloudfunctions.net/getCompanyPoints?companyId="+ orgSponsor : "http://localhost:5001/inwardstrong-e017e/us-central1/getCompanyPoints?companyId=" + orgSponsor

    return fetch( url, { 
        method: "GET",
        headers: {
            "X-IS-APP-KEY": "02tG6z9BpzwzH48rSTOxYKBEfdpWmgEc"
        },
        credentials: 'include'
     }).then( ( response ) => {
        if ( response.status !== 200 ) throw new Error("Unauthorized Access!");

        return response.json()
                    .then(( data ) => {
                        return parseGoogleAnalyticsResponse( data.response );
                    })
     })
}

/**
 * Create my carepathway module list based on my quiz outcome and user choices
 * @param { Array } Modules list
 * @param { Object } User Profile object
 * @param { Array } default CarePathway order
 * @return{ Array } Modules list - curated for the given user
 */
function curateMyCarePathway( activeProgram, userProfile ){

    let carePathway = ( activeProgram.slug === DEFAULT_PROGRAM_SLUG ) ? userProfile.carePathway : userProfile?.programs?.[activeProgram.slug]?.carePathway;

    if ( userProfile && carePathway && carePathway.length > 0 ){

        let myVerifiedCarePathway = checkAndUpdateCarePathwayForSpecialModules( [ ...carePathway ], activeProgram.defaultCarePathway.CAREPATHWAY_SPECIAL_MODULES );

        if ( myVerifiedCarePathway.length !== carePathway.length ) {
            updateMyCarePathway( userProfile.id, myVerifiedCarePathway, activeProgram.slug );
        }

        return setUpSessionsForMyCarePathway( activeProgram.sessions, myVerifiedCarePathway );
    } else if ( activeProgram && activeProgram.requiresOnBoarding && userProfile && userProfile.quizResponses && userProfile.quizResponses["care-pathway-needs-assessment"] ) {
        let myCarePathwayOrder = evaluateMyCarePathway( userProfile, activeProgram )
        updateMyCarePathway( userProfile.id, myCarePathwayOrder, activeProgram.slug );
        return setUpSessionsForMyCarePathway( activeProgram.sessions, myCarePathwayOrder );
    } else {
        let defaultPathwayOrder =  [ ...activeProgram.defaultCarePathway.CAREPATHWAY_INTRO_MODULES, ...activeProgram.defaultCarePathway.CAREPATHWAY_DEFAULT_ORDER, ...activeProgram.defaultCarePathway.CAREPATHWAY_SPECIAL_MODULES ]
        updateMyCarePathway( userProfile.id, defaultPathwayOrder, activeProgram.slug );
        return setUpSessionsForMyCarePathway( activeProgram.sessions, defaultPathwayOrder );
    }

}

/**
 * Rearrange the carepathway outcome based on actionType
 * @param {Object} currentSession 
 * @param {String} actionType ( skip, finished, unlock )
 * @param {Array} carepathway order current carepathway order
 * @param {Object} featuredSession ( if available, else null )
 * @return {Array} carepathway order updated carepathway order
 */
function rearrangeMyCarepathway( currentSession, actionType, currentCarePathway, featuredSession = null ){

    if ( actionType === 'skip' || actionType === 'finished' ) {
        let modifiedCarePathway = currentCarePathway.filter((module) => module !== currentSession.slug )
        modifiedCarePathway.push( currentSession.slug );
        return modifiedCarePathway;
    } else if ( featuredSession && actionType === 'unlock' ) {
        let featuredIndex =  currentCarePathway.findIndex((module) => module === featuredSession.slug )
        let modifiedCarePathway = currentCarePathway.slice( 0, featuredIndex + 1 )
        modifiedCarePathway.push( currentSession.slug );

        let remainingModules = currentCarePathway.filter( (module) => !modifiedCarePathway.includes(module) )

        return modifiedCarePathway.concat( remainingModules );

    } else {
        return currentCarePathway;
    }

}

/**
 * Get the Featured Module for the given user by the currentActiveProgram
 * @param {Object} userProfile 
 * @param {Array} array of sessions for the current active program. The sessions here are based on the care pathway order.
 * @param {ActiveProgram} The current active program
 * @returns {Object} The featured module
 */
function getMyFeaturedModule( userProfile, sessions, activeProgram ){

    if ( userProfile && activeProgram && activeProgram.slug === DEFAULT_PROGRAM_SLUG ) {
        
        if ( userProfile.currentModule ) {
            let featuredModule = activeProgram.sessions.find((session) => session.slug === userProfile.currentModule )
            return featuredModule ? featuredModule : sessions[0]
        }
    } else if ( userProfile && activeProgram && activeProgram.slug !== DEFAULT_PROGRAM_SLUG  ) {
        if ( userProfile.programs && userProfile.programs[activeProgram.slug] && userProfile.programs[activeProgram.slug].currentModule ) {
            let featuredModule = activeProgram.sessions.find((session) => session.slug === userProfile.programs[activeProgram.slug].currentModule)
            return featuredModule ? featuredModule : sessions[0]
        }
    }

    return sessions[0];
}

/**
 * Evaluate a weighted score and create my carepathway. Save this pathway to the database
 * @param {Object} userProfile 
 * @param {Object} activeProgram
 */
function evaluateMyCarePathway( userProfile, activeProgram ){

    let { CAREPATHWAY_EVALUATION_WEIGHTS } = APP_CONFIG

    let modules = Object.keys(CAREPATHWAY_EVALUATION_WEIGHTS);
    let evaluationScore = { "stress": 0, "anxiety": 0, "relationships": 0, "trauma": 0 }
    let curatedCarePathway = []

    let responses = userProfile.quizResponses["care-pathway-needs-assessment"];

    responses.forEach((response, questionNo) => {

        modules.forEach((module)=>{
            if ( CAREPATHWAY_EVALUATION_WEIGHTS[module].includes( questionNo ) )
                evaluationScore[module] = evaluationScore[ module ] + response
        })

    })

    let sortedScoreSet = [ ...new Set(Object.values( evaluationScore ).sort((a,b) => a-b ))];
    sortedScoreSet.forEach(( score ) => {
        curatedCarePathway = curatedCarePathway.concat(getKeyByValue( evaluationScore, score ));
    });

    logAnalyticEvent( Events.SUGGESTED_FOCUS_AREAS, createEventData({ primary_focus_area: curatedCarePathway.length > 0 ? curatedCarePathway[0] : "None", secondary_focus_area: curatedCarePathway.length > 1 ? curatedCarePathway[1] : "None", sponsoring_org: userProfile.orgSponsor }) )

    return [ ...activeProgram.defaultCarePathway.CAREPATHWAY_INTRO_MODULES, ...curatedCarePathway, ...activeProgram.defaultCarePathway.CAREPATHWAY_SPECIAL_MODULES ];
}

/**
 * Evaluate a weighted score and create my carepathway. Save this pathway to the database
 * @param {Array} modules list
 * @param {Array} carepathway order list
 * @return {Array} Modules - Organized by the given order
 */
 function setUpSessionsForMyCarePathway( sessions, carePathwayOrder ){

    let mySessions = []

    carePathwayOrder.forEach( (moduleSlug) => {
        var session = sessions.find((session) => session.slug === moduleSlug )
        if ( session ) mySessions.push( session );
    })

    return mySessions;
}

/**
 * Checks if the set up carepath way contains special modules, if it doesn't, include spl modules to the end of their pathway
 * @param {Array} current carePathway
 * @param {Array} special modules
 * @return {Array} user's up to date carepathway
 */
function checkAndUpdateCarePathwayForSpecialModules( myCarePathway, specialModules ){

    specialModules.forEach( (splModule) => {
        if ( !myCarePathway.includes( splModule )) myCarePathway.push( splModule );
    })

    return myCarePathway
}

/**
 * Add locked and next states based on users active and started modules
 * @param {Object} UserProfile
 * @param {Array} List of my carepathway sessions
 * @param {string} programSlug program slug
 * @return {Array} List of my carepathway sessions containg personilized session states
 */
function personalizeSessionStates( userProfile, carePathwaySessions, programSlug ){

    let startedModules = ( programSlug === DEFAULT_PROGRAM_SLUG ) ? userProfile.startedModules : userProfile?.programs?.[programSlug]?.startedModules;

    return carePathwaySessions.map((session, index) => {
        session.flagText = ( index === 1 ) ? "Next" : "";
        if ( (session.isLocked && startedModules && startedModules[session.slug]) || (session.isLocked && session.flagText === "Next") || (session.isLocked && index === 0) ) session.isLocked = false;
        return session;
    });
}

/**
 * Add's presonalized `next` states to chapter modules
 * @param {Array} chapters 
 * @param {Object} currentChapter 
 * @retur {Array} chapters that have personalized states
 */
function personalizeChapterStates( chapters, currentChapter ){

    const currentChapterIndex = chapters.findIndex((chapter)=> chapter.id === currentChapter.id);

    return chapters.map((chapter, index) => {
        chapter.flagText = ( index === currentChapterIndex + 1 ) ? "Next" : "";
        return chapter;
    })
}

/*
 * Helper Methods 
 */


/**
 * Get's the key name for the given 
 * @param { Object } object
 * @param { object } value 
 * @return { Array } List of keys that have the same value
 */
function getKeyByValue(object, value) {
    return Object.keys(object).filter(key => object[key] === value);
}

/**
 * Updates the favs to the user profile collection object
 * @param { Object } user profile
 * @param { String } itemType
 * @param { object } updated collection
 * @return { Object } userProfile
 */
function updateFavsToUserProfile( userProfile, itemType, updatedCollection ){
    if ( itemType === CONTENT_TYPES.SESSION ) {
        let favs = userProfile.favourites ? userProfile.favourites : {}
        let sessionFavs = { ...favs[itemType], ...updatedCollection }
        userProfile.favourites = { ...userProfile.favourites, session: sessionFavs }
    } else {
        userProfile.favourites = { ...userProfile.favourites, ...updatedCollection }
    }

    return userProfile;
}
  

export { 
    createISUserProfile,
    updateISUserDisplayName,
    updateISUserDisplayStatus,
    getISUserProfile,
    getAllOrganizations,
    getOrganizationByID,
    getActiveProgramName,
    getMyCarePathway,
    updateOrgSponsorForUser,
    updateMyCurrentSession,
    updateUsersQuizResponse,
    updateMyCarePathway,
    startSession,
    bookmarkSessionsChapter,
    getBookmarkedSessionsChapter,
    getCurrentChapterVideoSeekPos,
    setCurrentChapterPlaybackSeekPosition,
    addStressCheckIn,
    addMoodTrackingScores,
    updateRewardsPoints,
    updateActiveProgram,
    isItemFavourited,
    addToFavourites,
    removeFromFavourites,
    getAllSessions,
    getAllPrograms,
    getSessionByID,
    getSessionsByID,
    getFilters,
    getAllArticles,
    getArticleByID,
    getArticlesByID,
    getAllQuiz,
    getStressCheckinScales,
    getMoodTrackingScales,
    getCompanyTokens,
    curateMyCarePathway,
    rearrangeMyCarepathway,
    getMyFeaturedModule,
    personalizeSessionStates,
    personalizeChapterStates,
    CONTENT_TYPES
}