import React, { useRef, useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import ImageButton from "../../components/ImageButton";
import { createMessageModal } from "../../shared/utils/modals";
import winObj from "../../shared/utils/window";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import GoogleLogo from "../SignUp/assets/google-logo.png";
import FacebookLogo from "../SignUp/assets/facebook-logo.png";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import ERRORS from "../../app/constant";
import "./index.css";

const { AUTH_ERRORS } = ERRORS;

function Login({ dispatch }){

    const email = useRef();
    const password = useRef();
    const [googleProvider, setGoogleProvider] = useState(null);
    const [fbProvider, setFBProvider] = useState(null);
    const [buttonState, setButtonState] = useState(true);

    useEffect(()=>{

        let gProvider = new GoogleAuthProvider()
        gProvider.setCustomParameters({
            prompt: 'select_account'
          });

        setGoogleProvider(gProvider);
        setFBProvider(new FacebookAuthProvider());
        dispatch(updateISHeader(true, false))
    }, [dispatch])

    /* Event Handlers */
    function onEmailChange(){
        setButtonState(email.current.value.length === 0 || password.current.value.length === 0 )
    }

    function onPasswordChange(){
        setButtonState(email.current.value.length === 0 || password.current.value.length === 0 )
    }

    function signInWithEmail(){
        let emailValue = email.current.value;
        let passwordValue = password.current.value;

        signInWithEmailAndPassword(getAuth(), emailValue, passwordValue)
            .then(()=>{
                logAnalyticEvent( Events.LOGIN, createEventData( { method: "email" } ) );
                winObj.location.href = "/";
            })
            .catch((error)=>{
                let errMessage = AUTH_ERRORS.LOGIN_ERRORS[ error.code ]

                if (!errMessage) {
                    errMessage = "Something went wrong. Please check credentials and try again";
                }
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, errMessage) } });
            })

    }

    function signInWithGoogle(){
        signInWithRedirect(getAuth(), googleProvider);
    }

    function signInWithFacebook(){
        signInWithRedirect(getAuth(), fbProvider);
    }

    return(
        <div className="Login-Container">

            <h2> Welcome Back, </h2>

            <div className="UserName InputField">
                <p> Enter your email </p>
                <input type="text" ref={email} id="username" name="username" onChange={onEmailChange} />
            </div>

            <div className="Password InputField">
                <p> Enter your password </p>
                <input type="password" ref={password} id="password" name="password" onChange={onPasswordChange} />
            </div>

            <div className="Terms">
                <a href="/#/ForgotPassword" rel="noreferrer" style={{position: "relative", bottom: "3px", left: "4px"}}> Forgotten Password? </a>
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Submit" onClick={signInWithEmail} /> 
            </div>

            <div className="AccountTypes">
                <p> Sign In With </p> 
                <ImageButton className="Google" logo={GoogleLogo} value="Continue with Google" onClick={signInWithGoogle} />
                <ImageButton className="Facebook" logo={FacebookLogo} value="Continue with Facebook" onClick={signInWithFacebook} />
            </div>

        </div>
    )
}

export default StoreConnect(Login);