import React, { useEffect, useState } from "react"
import { getAuth } from "@firebase/auth";
import { DateTime } from "luxon";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import AssessmentCard from "../../components/assessmentCard";
import ScoreCard from "../../components/scoreCard";
import { getAllQuiz, getISUserProfile, getMoodTrackingScales } from "../../shared/middleware";
import { createScoreCardDetailsModal } from "../../shared/utils/modals";
import { getScoreLabel } from "../../shared/utils/assessments";
import { logAutoEvent } from "../../shared/utils/analytics";
import winObj from "../../shared/utils/window";
import "./index.css";

const PREFFERED_ASSESSMENTS = [ "GAD7", "PHQ9" ];
const DATE_TIME_FORMAT = "yyyy-LL-dd:HH";

function MoodTracker({dispatch}){

    const [ auth ] = useState(getAuth());
    const [ userProfile, setUserProfile ] = useState(null);
    const [ assessments, setAssessments ] = useState([]);
    const [ scores, setScores ] = useState([]);
    const [ moodTrackingScales, setMoodTrackingScales ] = useState();

    useEffect(() =>{
        dispatch( updateISHeader(true, null, null) )
    }, [ dispatch ])

    useEffect(() =>{

        if (winObj.document) winObj.document.title = "InwardStrong - Mood Tracker"

        getAllQuiz()
            .then( (allQuiz) =>{

                let moodTrackingAssessments = allQuiz.filter( (quiz) => {
                    return PREFFERED_ASSESSMENTS.includes( quiz.slug )
                })

                setAssessments(moodTrackingAssessments);
            })

        getMoodTrackingScales()
            .then((data) => {
                if ( data ) setMoodTrackingScales( data );
            })

        logAutoEvent();

    }, [])

    useEffect(() => {

        if ( auth.currentUser ) {
            getISUserProfile( auth.currentUser.uid )
                .then( ( userProfileSnapshot ) => {
                    let ISUserProfile = userProfileSnapshot.val();
                    setUserProfile( ISUserProfile )
                })
        }

    }, [auth])

    useEffect( () => {

        if ( userProfile && userProfile.moodTrackingScores ){
            setScores( createStressScoreCards( Object.entries(userProfile.moodTrackingScores).sort( sortByLatestCheckIns ) ) )
        }

    }, [ userProfile, moodTrackingScales ] )

    const onAssessmentStart = ( assessment ) =>{
        winObj.location.href = "/#/Quiz/" + assessment.slug;
    }

    const onCardClick = ( card ) => {
        let scoreCardDetailsModel = createScoreCardDetailModel( DateTime.fromFormat(card.date, "DD").toLocal(), card.firstScore, card.secondScore, moodTrackingScales.scale );
        let modal = createScoreCardDetailsModal(scoreCardDetailsModel, "html");
        dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modal } } )
    }

    return (
        <div className="MoodTracker-Container">
            <h2> Mood Tracker </h2>

            <h4> By completing these two Cognitive Behavioural Therapy (CBT) assessments every few weeks, you'll  keep track of your mental state. </h4>

            <div className="Assessments">
                
                {  
                    assessments.map( ( assessment, index ) => {
                        return <AssessmentCard key={index} assessment={assessment} onAssessmentStart={onAssessmentStart} />
                    })
                }

            </div>

            { scores && scores.length > 0 ? 
            <div className="ScoreContainer">
                <h3> History </h3>
                { scores.map((scorecard, index) => 
                    <ScoreCard key={index} card={scorecard} onCardSelected={onCardClick} />
                )}
            </div> 
            : null }

        </div>
    )
}

export default StoreConnect(MoodTracker);


/**
 * Creates scorecards based on stress check-in results
 * @param {Object} stressCheckIns objects by date/hr format 
 * @return {Object} scorecard objects
 */
function createStressScoreCards( checkIns ){
    return checkIns.map( ( [ checkInDateHr, results ]) => {
        return {
            date: DateTime.fromFormat(checkInDateHr, DATE_TIME_FORMAT).toLocal().toFormat("DD"),
            firstScore: ( results.GAD7 ? parseInt(results.GAD7.score) : null ),
            firstScoreLabel: ( results.GAD7 ? getScoreLabel(results.GAD7.score) + " Anxiety" : null ),
            secondScore: ( results.PHQ9 ? parseInt(results.PHQ9.score) : null ),
            secondScoreLabel: ( results.PHQ9 ? getScoreLabel(results.PHQ9.score)+ " Depression" : null ),
        }
    })
}


/**
 * Creates a scorecard details model
 * @param {object} dateTime in local
 * @param {int} stressScore 
 * @param {int} copingScore 
 * @returns 
 */
function createScoreCardDetailModel( dateTime, gadScore, phqScore, moodTrackScale ){

    let resultCard = {
        date: dateTime.toFormat('ccc, d LLL y'),
        scores: []
    }

    if ( gadScore ) {
        let anxietyResults = {
            scoreTitle: "Score: " + gadScore + " " + getScoreLabel(gadScore) + " Anxiety",
            scoreDescription: moodTrackScale["GAD7"][getScoreLabel(gadScore).toLowerCase()]
        }

        resultCard.scores.push( anxietyResults );
    }

    if ( phqScore ) {
        let depressionResults = {
            scoreTitle: "Score: " + phqScore + " " + getScoreLabel(phqScore) + " Depression",
            scoreDescription: moodTrackScale["PHQ9"][getScoreLabel(phqScore).toLowerCase()]
        }

        resultCard.scores.push( depressionResults );
    }

    return resultCard;
}

/**
 * sorts the stress check-in entries by date
 * @param {*} aCheckIn 
 * @param {*} bCheckIn 
 * @returns 
 */
function sortByLatestCheckIns( aCheckIn, bCheckIn ){
    const [ aDateFormat ] = aCheckIn
    const [ bDateFormat ] = bCheckIn

    const aDateTime = DateTime.fromFormat(aDateFormat, DATE_TIME_FORMAT).toUTC();
    const bDateTime = DateTime.fromFormat(bDateFormat, DATE_TIME_FORMAT).toUTC();

    return bDateTime.toMillis() - aDateTime.toMillis();
}