import React, { useEffect, useState, useRef } from "react";
import ArticleListItem from "../../components/articleListItem";
import FilterChips from "../../components/filterChips";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { getFilters, getAllArticles } from "../../shared/middleware";
import { logAutoEvent } from "../../shared/utils/analytics";
import winObj from "../../shared/utils/window";
import "./index.css"

function Tools({ dispatch }){

    const [ filters, setFilters ] = useState([])
    const [ articles, setArticles ] = useState([]);
    const [ selectedChips, setSelectedChips ] = useState([]);
    let toolListRef = useRef();

    useEffect(()=>{
        dispatch(updateISHeader(true, null))
    },[dispatch])


    useEffect(()=>{

        if (winObj.document) winObj.document.title = "InwardStrong - Tools"

        getFilters()
            .then( (data) =>{
                setFilters(data);
            })

        logAutoEvent();

    }, [])

    useEffect(()=>{

        getAllArticles(selectedChips)
            .then((articles)=>{
                if ( toolListRef.current ) toolListRef.current.scrollTo(0,0);
                setArticles(articles);
            })

    }, [ selectedChips ])

    const onFiltersSelected = ( filters ) => {
        let selectedFilters = filters.filter( (filter) => filter.isSelected )
        setSelectedChips( selectedFilters.length > 0 ? selectedFilters.map( ( filter ) => filter.id ) : [] );
    }

    return (
        <div className="Tools-Container">
            <h2> Toolbox </h2>

            <div className="Tools">

                { filters.length > 0 ?
                    <div className="Tags-List">
                        <FilterChips title="" filters={filters} onChipsSelected={onFiltersSelected} />
                    </div> 
                : null }

                <div ref={toolListRef} className="Tools-List">
                    { articles.map( ( article ) =>
                        <ArticleListItem key={article.id} article={article} />
                    )}
                </div>

            </div>

        </div>
    )
}

export default StoreConnect(Tools);
