import Arrow from "./arrow.js";

export default Object.freeze({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    swipeToSlide: false,
    variableWidth: false,
    responsive: [
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 2.4,
            slidesToScroll: 2
          }
        }
    ],
    nextArrow: <Arrow />,
    prevArrow: <Arrow />
})