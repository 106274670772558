/**
 * Contains a list of adapters that return data as data models
 */

/**
 * Returns a list of session models
 * @param { Array } List of Entries object
 * @return { Array } List of Session Models
 */
function parseItemsToSessions( items ){
    return items.map( createSessionModel )
}

/**
 * Returns a list of program models
 * @param { Array } List of Program entries
 * @param { Array } list of program models
 */
function parseItemsToPrograms( items ) {
    return items.map( createProgramModel )
}


/**
 * Returns a list of chapters models
 * @param { Array } List of Entries object
 * @return { Array } List of chapters Models
 */
function parseItemsToChapters( items ){
    return items.map( createChapterModel )
}

/**
 * Returns a list of filter models
 * @param {Array} items of Tags object
 * @return {Array} list of filter models
 */
function parseItemstoFilters( items ){
    return items.map( createFilterModel )
}

/**
 * Returns a list of quiz models
 * @param {Array} items of Quiz object
 * @return {Array} list of Quiz model objects
 */
 function parseItemstoQuiz( items ){
    return items.map( createQuizModel )
}

/**
 * Returns a list of article models
 * @param {Array} items of Article entry object
 * @return {Array} list of articles models
 */
 function parseItemstoArticles( items ){
    return items.map( createArticleModel )
}

/**
 * Creates a single session model
 * @param {} item 
 * @param {*} index 
 * @returns 
 */
function createSessionModel( item, index = 0 ){
    return {
        title: item?.fields?.title,
        slug: item?.fields?.slug,
        description: item?.fields?.description,
        link: "",
        artwork: createArtworkLink( item?.fields?.artwork ),
        chapters: ( item?.fields?.chapters ) ? parseItemsToChapters( item?.fields?.chapters ) : [],
        isLocked: !item?.fields?.canSkip,
        flagText: index === 1 ? "Next" : "",
        id: item?.sys?.id,
        type: "session"
    }
}

/**
 * Creates a single program model
 * @param {object} item Program entry
 * @param {int} index 
 * @return {Object} item program model
 */
function createProgramModel( item ){
    return {
        title: item?.fields?.name,
        slug: item?.fields?.slug,
        description: item?.fields?.description,
        artwork: createArtworkLink( item?.fields?.artwork ),
        sessions: ( item.fields.sessions ) ? parseItemsToSessions(item.fields.sessions) : [],
        isDefault: item?.fields?.isDefault,
        requiresOnBoarding: item?.fields?.requiresOnBoarding,
        defaultCarePathway: item?.fields?.defaultCarePathway,
        link: "",
        id: item?.sys?.id,
        type: "program"
    }
}

/**
 * Creates a single filter model
 * @param {*} item 
 */
function createFilterModel( item ) {
    return {
        name: item.name,
        id: item?.sys?.id,
        type: "filter"
    }
}

/**
 * Creates a single chapter model
 * @param {*} item 
 * @returns 
 */
function createChapterModel( item, index = 0 ) {
    return {
        title: item?.fields?.title,
        description: item?.fields?.description,
        link: "",
        artwork: createArtworkLink( item?.fields?.artwork ),
        toolInserts: ( item?.fields?.toolInserts ),
        videoSource: item?.fields?.videoSource,
        id: item?.sys?.id,
        flagText: "",
        type: "chapter"
    }
}

/**
 * Creates an Article model
 * @param {Object} item 
 * @return {Object} Article model
 */
function createArticleModel( item ) {
    return {
        title: item?.fields?.title,
        description: item?.fields?.description,
        referenceLink: item?.fields?.referenceLink,
        artwork: createArtworkLink( item?.fields?.artwork ),
        id: item?.sys?.id,
        type: "article"
    }
}

/**
 * Creates an artwork link
 * @param {*} artwork 
 * @returns 
 */
function createArtworkLink( artwork ){

    if ( artwork && (artwork || artwork.length > 0 )) {
        let artObj = artwork.fields ? artwork : artwork[0];
        let imgURL = artObj?.fields?.file?.url
        return imgURL ? "https:" + imgURL : "";
    }

    return "";
}

/**
 * Creates a quiz model
 * @param {Object} item 
 * @return {Object} Quiz Model
 */
function createQuizModel( item ) {
    return {
        title: item?.fields?.title,
        name: item?.fields?.name,
        slug: item?.fields?.slug,
        questions: item?.fields?.questions?.items,
        completionRouteURL: item?.fields?.completionRouteUrl ,
        canSkip: item?.fields?.canSkip,
        id: item?.sys?.id,
        type: "quiz"
    }
}

/**
 * Parse Google Analytics data into Google analytics model
 * @param {Object} Response - GA Response object
 * @return {Array} Google Analytics Model
 */
function parseGoogleAnalyticsResponse( response ){
    if ( response && response.rows && response.rows.length <= 0 ) return null;

    const dimensions = response.dimensionHeaders;
    const metrics = response.metricHeaders;

    const GAModel = response.rows.map(( row ) => {

        let rowData = {}

        dimensions.forEach( ( header, index ) => {
            rowData[ header.name ] = row.dimensionValues[index].value
        })

        metrics.forEach( ( metric, index ) => {
            rowData[ metric.name ] = row.metricValues[index].value
        })

        return rowData;
    })

    return GAModel;
}



export { 
    parseItemsToSessions,
    parseItemsToChapters,
    parseItemstoFilters,
    parseItemstoArticles,
    parseItemsToPrograms,
    parseItemstoQuiz,
    createSessionModel,
    createChapterModel,
    createArticleModel,
    createProgramModel,
    parseGoogleAnalyticsResponse
 }