import React, { useEffect } from "react";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import awardPoints from "../../shared/utils/rewards";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import "./index.css";

function WeeklyTipScreen( { dispatch, store } ){

    const iStyle = { border: "none" }

    useEffect(()=>{
        let awardTimer = null;
        dispatch({ type: "FOOTER", data: { display: false } })

        if ( store.userProfile ) {
            awardTimer = setTimeout(() => {
                const updatedtokens = awardPoints( store.userProfile.id, 'WEEKLY_TIP', store.tokens, store.userProfile.orgSponsor );
                dispatch( { type: 'TOKENS', data: updatedtokens } )
            }, 5000);
        }

        return (()=>{
            dispatch({ type: "FOOTER", data: { display: true } })
            if ( awardTimer ) clearTimeout(awardTimer);
        })
        
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        dispatch(updateISHeader(true, null))
    },[dispatch])

    return(
        <div className="Article-Container">
            <iframe key="WeeklyForm" title="Weekly Tip" src="https://gcdkwu3o.paperform.co/" style={iStyle} />
        </div>
    )
}

export default StoreConnect(WeeklyTipScreen)