import React, { useEffect } from "react"
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import "./index.css";

function ReportIssue({dispatch, match}){

    const { type } = match.params;

    useEffect(() =>{
        dispatch( updateISHeader(true, null, null) )
    }, [ dispatch ])

    return (
        <div className="Mindful-Minute-Container">
            { ( type === "help" ) ? 
                <iframe src="https://kddcrrhi.paperform.co/" title="Ask for Help" />
                : ( type === "delete" ) ? <iframe src="https://4waogvvp.paperform.co/" title="Report an Issue" />
                    : <iframe src="https://obk8irj8.paperform.co/" title="Report an Issue" />
            }
        </div>
    )
}

export default StoreConnect(ReportIssue);