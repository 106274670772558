
import HELP_ICON from "./assets/help-self.svg";
import SERVICE_ICON from "./assets/service-line.svg";
import CARE_ICON from "./assets/care.svg";
import PATH_ICON from "./assets/path.svg";

export default Object.freeze({
    options: [
        {
            id: "option-1",
            name: "I’m on my own journey",
            pictureURL: SERVICE_ICON,
            message: "We'll develop your personalized care pathway based on your answers to the short assessment you're about to see. Please be as honest as possible in your responses. Responses you provide are strictly confidential.",
            messageIcon: CARE_ICON,
            className: "decreasedPadding"
        },
        {
            id: "option-2",
            name: "I’m here to support someone else",
            pictureURL: HELP_ICON,
            message: "Put yourself in the shoes of the person you have in mind, and answer the following questions as you think they would. Responses you provide are strictly confidential.",
            messageIcon: PATH_ICON,
            className: "decreasedPadding"
        }
    ]
});