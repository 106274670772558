import React, { useEffect } from "react"
import awardPoints from "../../shared/utils/rewards";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { logAutoEvent } from "../../shared/utils/analytics";
import winObj from "../../shared/utils/window";
import "./index.css";

function MindfulMinute({dispatch, store}){

    useEffect(() =>{
        dispatch( updateISHeader(true, null, null) )
    }, [ dispatch ])

    useEffect(() => {

        let awardTimer = null;
        if (winObj.document) winObj.document.title = "InwardStrong - Mindful Minute"

        if ( store.userProfile ) {
            awardTimer = setTimeout(() => {
                const updatedtokens = awardPoints( store.userProfile.id, 'LOG_MINDFUL_MINUTE', store.tokens, store.userProfile.orgSponsor );
                dispatch( { type: 'TOKENS', data: updatedtokens } )
            }, 15000);
        }

        logAutoEvent();

        return (() => {
            if ( awardTimer ) clearTimeout(awardTimer);
        })
    
    // eslint-disable-next-line        
    }, [])


    return (
        <div className="Mindful-Minute-Container">
            <iframe src="https://frmwuyfy.paperform.co" title="Mindful Minute" />
        </div>
    )
}

export default StoreConnect(MindfulMinute);