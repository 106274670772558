import { useState, useEffect } from "react";

function useAppVisibility( winObj ){

    const [ visibilityState, setVisibilityState ] = useState( winObj.document.visibilityState )

    useEffect(()=>{

        const visibilityChangeHandler = () => {
            setVisibilityState( winObj.document.visibilityState );
        }


        winObj.document.addEventListener('visibilitychange', visibilityChangeHandler );

        return (() => {
            winObj.document.removeEventListener('visibilitychange', visibilityChangeHandler);
        })
        
    // eslint-disable-next-line
    }, [])

    return visibilityState;
}

export default useAppVisibility;