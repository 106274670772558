import { updateRewardsPoints } from "../middleware";
import Events, { logAnalyticEvent, createEventData } from "./analytics";

const ELIBIBLE_TASKS = {
    "WELCOME_GOAL_COMPLETION": 1,
    "CAREPATHWAY_ONBOARDING_COMPLETION" : 1,
    "ARTICLE_VIEW": 1,
    "CHAPTER_VIEW_COMPLETE": 1,
    "LOG_STRESS": 1,
    "LOG_COPING": 1,
    "LOG_MINDFUL_MINUTE": 1,
    "MOOD_TRACKING_COMPLETE": 1,
    "WEEKLY_TIP": 1
}

/**
 * Awards points for eligible task's for the given user
 * @param {string} UserID
 * @param {string} Task Name 
 * @param {int} points tally
 * @param {int} ID org sponsor ID
 * @return {points} updated points tally
 */
function awardPoints( userID, taskName, points, orgSponsorID = null ){

    const eligibleTaskPoint = ELIBIBLE_TASKS[taskName];

    if ( eligibleTaskPoint ){
        const newPoints = points + eligibleTaskPoint
        updateRewardsPoints( userID, newPoints );
        if ( orgSponsorID ) logAnalyticEvent( Events.ORG_TOKEN, createEventData({ company_token_value: eligibleTaskPoint, sponsoring_org: orgSponsorID }) )
        return newPoints;
    }

    return points;
}

export default awardPoints