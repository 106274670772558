

import HEART_ICON from "./assets/heart-pulse-line.svg";
import TOOL_ICON from "./assets/hammer-line.svg";
import MENTAL_ICON from "./assets/mental-health-line.svg";
import TIMER_ICON from "./assets/timer-flash-line.svg";

export default Object.freeze({
    menu: [
        {
            id: "option-1",
            name: "Stress Check-In",
            pictureURL: HEART_ICON,
            routeURL: "/#/StressCheckin"
        },
        {
            id: "option-2",
            name: "Toolbox",
            pictureURL: TOOL_ICON,
            routeURL: "/#/Tools"
        },
        {
            id: "option-3",
            name: "Mood Tracker",
            pictureURL: MENTAL_ICON,
            routeURL: "/#/MoodTracker"
        },
        {
            id: "option-4",
            name: "Mindful Minute",
            pictureURL: TIMER_ICON,
            routeURL: "/#/MindfulMinute"
        }
    ]
});