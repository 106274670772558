import MessageModal from '../../components/messageModal';
import InstructionsModal from '../../components/instructionsModal';
import ScoreCardDetails from '../../components/scoreCardDetails';

/* Contains methods that create and pop up modals */

/**
 * Creates a message modal component
 * @param {String} title 
 * @param {String} icon url
 * @param {String} buttonName 
 * @param {Function} buttonHandler 
 * @param {Component} children 
 * @return {Compoent} Modal
 */
 function createMessageModal(title, icon, buttonName, buttonHandler, message ){
    return (
      <MessageModal title={title} icon={icon} buttonTitle={buttonName} buttonHandler={buttonHandler}> 
        <div dangerouslySetInnerHTML={{ __html: "<p>" + message + "</p>" }}></div>
      </MessageModal>
    )
}

/**
 * Creates an instruction message modal component
 * @param {String} title 
 * @param {String} icon url
 * @param {String} buttonName 
 * @param {Function} buttonHandler 
 * @param {Component} children 
 * @return {Compoent} Modal
 */
function createInstructionModal(title, icon, buttonName, buttonHandler){
  return (
    <MessageModal title={title} icon={icon} buttonTitle={buttonName} buttonHandler={buttonHandler}> 
      <InstructionsModal />
    </MessageModal>
  )
}

/**
 * Creates a password verification modal
 * @param {String} title 
 * @param {String} icon url
 * @param {String} buttonName 
 * @param {Function} buttonHandler 
 * @param {Ref} passwordRef
 * @return {Compoent} Modal
 */
function createPasswordVerificationModal( title, icon, buttonName, buttonHandler, passwordRef ){
  return (<MessageModal title={title} icon={icon} buttonTitle={buttonName} buttonHandler={buttonHandler}> 
    <input type="password" ref={passwordRef} id="password" name="password" placeholder="Type your password" />
  </MessageModal>)
}

/**
 * Returns a ScoreCardDetails Modal
 * @param {object} Object - ScoreCardDetails object
 * @param {string} Format -  text or html
 */
function createScoreCardDetailsModal( cardDetails, format="text" ){
  return (
    <ScoreCardDetails scoreCard={cardDetails} format={format} />
  )
}

export { createMessageModal, createInstructionModal, createPasswordVerificationModal, createScoreCardDetailsModal }