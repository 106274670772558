import React, { useContext } from "react";

const StoreContext = React.createContext({});

/**
 * Creates a context Provider wrapper component for App Store
 * @param {props} containg store, dispacth and children 
 */
function StoreProvider({ store, dispatch, children }){
    return (
        <StoreContext.Provider value={{ store, dispatch }} >
            { children }
        </StoreContext.Provider>
    );
}

/**
 * A HOC component that returns a Wrapped Component with Store and dispacth context information
 * @returns { Component } HOC component
 */
function StoreConnect( WrappedComponent ){

    const HOC = (props) => {
        const appStoreContext = useContext(StoreContext)
        return (
            <WrappedComponent store={appStoreContext.store} dispatch={appStoreContext.dispatch} {...props} />
        )
    }

    return HOC
}

export { StoreProvider, StoreConnect };
