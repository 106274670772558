import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css"
import winObj from "../../shared/utils/window";

function NoteCard( { note, link } ){

    const [ cardOpacity, setCardOpacity ] = useState(0);

    useEffect(() => {
        setCardOpacity(0);

        let opacityTimer = setTimeout(() => {
            setCardOpacity(1);
        }, 2000);

        return (() => {
            clearTimeout(opacityTimer);
        })
    }, [ note ])


    const onCardClick = () => {
        if ( link && link.href && link.href.length > 0 ) winObj.location.href = link.href;
    }

    return (
        <div className="NoteCard" style={{ opacity: cardOpacity }} onClick={onCardClick} >
            { cardOpacity !== 0 ? 
                (<>
                    <h4> Tip/Technique </h4>
                    <div className="Contents">
                        <p> {note} </p>
                        <p className="highlight"> {link.text} </p>
                    </div>
                </>) : null 
            }
        </div>
    )
}

NoteCard.propTypes = {
    note: PropTypes.string,
    link: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string
    })
}

export default NoteCard