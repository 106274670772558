import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { getAuth } from "firebase/auth"
import { DateTime } from "luxon";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import ProgressBar from "../../components/progressBar";
import MultipleChoiceQuestion from "../../components/multipleChoiceQuestion";
import { updateISHeader, createSkipAllAction } from "../../shared/utils/dispatchHelpers";
import winObj from "../../shared/utils/window";
import { getAllQuiz, updateUsersQuizResponse, addMoodTrackingScores, getMoodTrackingScales } from "../../shared/middleware";
import awardPoints from "../../shared/utils/rewards";
import { tallyScores, getScoreLabel } from "../../shared/utils/assessments";
import { createScoreCardDetailsModal } from "../../shared/utils/modals";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import "./index.css";

const DATE_TIME_FORMAT = "yyyy-LL-dd:HH";

function Quiz({ store, dispatch, match }){

    const { quizID } = match.params;
    const { quiz: quizState } = store
    const [ buttonState, setButtonState ] = useState(true);
    const [ buttonName, setButtonName ] = useState("Next");
    const [ quizList, setQuizList ] = useState([]);
    const [ quiz, setQuiz ] = useState({});
    const [ currentQuestion, setCurrentQuestion ] = useState({});
    const [ currentQuestionIndex, setCurrentQuestionIndex ] = useState(0);
    const [ answerIndex, setAnswerIndex ] = useState(null);
    const [ progressPercent, setProgressPercent ] = useState(0);
    const [ moodTrackingScales, setMoodTrackingScales ] = useState();
    const [ auth ] = useState(getAuth())
    const { search } = useLocation();
    let history = useHistory();

    useEffect(()=>{
        dispatch(updateISHeader(true, false, null))
    },[dispatch])

    useEffect(() =>{
        getAllQuiz()
            .then( (allQuiz) =>{
                setQuizList(allQuiz);
            })

        getMoodTrackingScales()
            .then((data) => {
                if ( data ) setMoodTrackingScales( data );
            })
        
    }, [])

    useEffect(()=>{
        const urlParams = new URLSearchParams(search);
        ( urlParams.has("questionIndex") ) ? setCurrentQuestionIndex(parseInt(urlParams.get("questionIndex"))) : setCurrentQuestionIndex(0); 
    }, [search])

    useEffect(()=>{
        if ( quizList && quizList.length > 0 ) {
            let selectedQuiz = quizList.find( (quiz) => {
                return ( quiz.slug === quizID )   
            })

            if ( selectedQuiz ) {
                setQuiz( selectedQuiz )
                if ( selectedQuiz.canSkip ) dispatch(updateISHeader(true, false, createSkipAllAction( onSkipAction )))
            }

        }
    
    // eslint-disable-next-line 
    }, [setQuiz, quizID, quizList])

    useEffect(()=>{
        if ( quiz && quiz.questions && quiz.questions.length > 0 ){

            let totalQuestions = quiz.questions.length - 1
            let percent = currentQuestionIndex !== 0 ? (currentQuestionIndex / totalQuestions )*100 : ((currentQuestionIndex + 1) / (totalQuestions + 1) )*100

            if ( currentQuestionIndex === 0 ) {
                logAnalyticEvent( Events.QUIZ_BEGIN, createEventData( { quiz_name: quiz.slug, sponsoring_org: store.userProfile.orgSponsor }) )
            }

            setCurrentQuestion( quiz.questions[currentQuestionIndex] )
            setProgressPercent(Math.round(percent));
            ( currentQuestionIndex === totalQuestions ) ? setButtonName("Finish") : setButtonName("Next");
        }
    // eslint-disable-next-line 
    }, [quiz, currentQuestionIndex])

    const onNext = ()=> {

        if ( quizState && !quizState[quizID] )
            quizState[quizID] = [];
        
        quizState[quizID][currentQuestionIndex] = answerIndex;
        dispatch({ type: "QUIZ", data: quizState });

        setAnswerIndex(null);
        setButtonState(true);

        let nextIndex = (currentQuestionIndex >= 0 && currentQuestionIndex < (quiz.questions.length - 1) ) ? currentQuestionIndex + 1 : 0;

        if ( nextIndex !== 0 ) {
            winObj.location.href = "/#/Quiz/" + quizID + "?questionIndex=" + nextIndex;
        } else {
            if ( auth && auth.currentUser ) {

                logAnalyticEvent( Events.QUIZ_COMPLETE, createEventData( { quiz_name: quiz.slug, sponsoring_org: store.userProfile.orgSponsor }) )

                if ( quiz.completionRouteURL === "tallyScores" ) {
                    let score = tallyScores( quizState[quizID] );
                    
                    addMoodTrackingScores( auth.currentUser.uid, DateTime.utc().toFormat(DATE_TIME_FORMAT), { name: quizID, score } )
                        .then(() => {

                            const updatedtokens = awardPoints( auth.currentUser.uid, 'MOOD_TRACKING_COMPLETE', store.tokens, store.userProfile.orgSponsor );
                            dispatch( { type: 'TOKENS', data: updatedtokens } )

                            
                            let scoreCardDetailsModel = createScoreCardDetailModel( DateTime.local(), score, quizID, moodTrackingScales );
                            let modal = createScoreCardDetailsModal(scoreCardDetailsModel, "html")

                            const onModalDismiss = () => {
                                history.go( quiz.questions.length * -1 );
                            }

                            dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modal, onDismiss: onModalDismiss } } )
                        })
                } else {
                    updateUsersQuizResponse(auth.currentUser.uid, quizState)
                        .then(() => {
                            const updatedtokens = awardPoints( auth.currentUser.uid, 'CAREPATHWAY_ONBOARDING_COMPLETION', store.tokens, store.userProfile.orgSponsor );
                            dispatch( { type: 'TOKENS', data: updatedtokens } )
                        })
                        .finally(()=>{
                            winObj.location.href = "/#/Home"
                        })
                }
            }
        }
    }

    const onMultipleChoiceSelection = (index) => {
        setAnswerIndex(index)
        setButtonState(false)
    }

    const onSkipAction = () => {
        logAnalyticEvent( Events.QUIZ_SKIP, createEventData( { quiz_name: quiz.slug, sponsoring_org: store.userProfile.orgSponsor }) )
    }

    return (
        <div className="Quiz-Container">
            { ( quiz ) ?
                <>
                    <h2> { quiz.title } </h2>

                    <ProgressBar progressPercent={progressPercent} />
                    { currentQuestion && currentQuestion.question ? 
                        <MultipleChoiceQuestion selectedChoice={answerIndex} question={currentQuestion.question} options={currentQuestion.options} onChoiceSelection={onMultipleChoiceSelection} />
                    : null }

                    <div className="Button-Container">
                        <ISButton disabled={buttonState} value={buttonName} onClick={onNext} /> 
                    </div>
                </>

            : null }

        </div>
    )
}

export default StoreConnect(Quiz)


/**
 * Creates a scorecard details model
 * @param {object} dateTime in local
 * @param {int} stressScore 
 * @param {int} copingScore 
 * @returns 
 */
function createScoreCardDetailModel( dateTime, score, assessmentType, moodTrackingScale ){

    let testName = "";
    if (assessmentType === "GAD7")
        testName = "Anxiety";
    else if (assessmentType === "PHQ9")
        testName = "Depression";
    
    let severity = getScoreLabel( score );

    return {
        date: dateTime.toFormat('ccc, d LLL y'),
        scores: [ {
            scoreTitle: "Score: " + score + " "+ severity + " " + testName,
            scoreDescription: moodTrackingScale?.scale[assessmentType][severity.toLowerCase()] 
        }]
    }
}