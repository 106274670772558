import React from "react";
import PropTypes from "prop-types";
import "./index.css";

function ScoreCardDetails({ scoreCard, format }){
    return (
        <div className="ScoreCardDetails">
            <h2> { scoreCard.date } </h2>
            
            { (scoreCard.scores && scoreCard.scores.length > 0 ) ? 
                scoreCard.scores.map( ( score, index ) => 
                    <div key={index} className="ScoreCardDetail"> 
                        <h4> { score.scoreTitle } </h4>
                        { format === "html" 
                            ? <HTMLScoreRenderer text={score.scoreDescription} />
                            : <p> { score.scoreDescription } </p> }
                    </div>
                ) 
            : null }

        </div>
    )
}

ScoreCardDetails.propTypes = {
    scoreCard: PropTypes.shape({
        date: PropTypes.string,
        scores: PropTypes.arrayOf(PropTypes.shape({
            scoreTitle: PropTypes.string,
            scoreDescription: PropTypes.string,
        }))
    }).isRequired,
    format: PropTypes.string
}

ScoreCardDetails.defaultProps = {
    format: "text"
}

export default ScoreCardDetails


/* A simple react component that renders html text */
function HTMLScoreRenderer( { text } ){
    return (
        <>
            <div className="HTMLScoreRenderer" dangerouslySetInnerHTML={{ __html: text }}></div> 
        </>
    )
}