import React, { useState, useEffect } from "react"
import { getAuth, signOut } from "firebase/auth"
import { StoreConnect } from "../../components/HOC/StoreConnector";
import LinkButton from "../../components/LinkButton";
import winObj from "../../shared/utils/window";
import { getISUserProfile } from "../../shared/middleware";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { logAutoEvent } from "../../shared/utils/analytics";
import ARROW_ICON from "./assets/Vector.png";
import BUG_ICON from "./assets/bug-line.svg";
import HELP_ICON from "./assets/question-mark.svg";
import LOCK_ICON from "./assets/lock-privacy.svg";
import TERMS_ICON from "./assets/terms-document.svg";

import "./index.css"

function Settings( { dispatch } ){

    const [ auth ] = useState(getAuth())
    const [ userProfile, setUserProfile ] = useState(null);


    useEffect(()=>{

        dispatch(updateISHeader(true, null));

        if ( auth.currentUser ) {

            getISUserProfile( auth.currentUser.uid )
                .then(( userProfileSnapshot ) => {
                    setUserProfile( userProfileSnapshot.val() );
                })

        }
    // eslint-disable-next-line
    }, [ auth ])

    useEffect(() => {
        if (winObj.document) winObj.document.title = "InwardStrong - Settings"
        logAutoEvent();
    }, [])

    const logout = () => {
        signOut(auth);
    }

    const openUserProfile = () => {
        winObj.location.href = "/#/Profile";
    }

    const openBugReport = () => {
        winObj.location.href = "/#/ReportIssue/bug";
    }

    const openHelpReport = () => {
        winObj.location.href = "/#/ReportIssue/help";
    } 

    const openTermsOfUse = () => {
        window.open("https://www.inwardstrong.com/terms-of-use", '_blank')
    }

    const openPrivacyPolicy = () => {
        window.open("https://www.inwardstrong.com/web-privacy-policy", '_blank')
    }

    return (
        <div className="Settings-Container">
            <h2> Hello { ( userProfile && userProfile.displayName) ? userProfile.displayName : auth.currentUser.displayName }, </h2>

            <div className="Profile-Details" onClick={openUserProfile}>
                <h4> My Profile </h4>
                <p> { auth.currentUser.email } </p>
                <img src={ARROW_ICON} alt="arrow-icon" />
            </div>

            <h3> More </h3>

            <div className="Settings-Details" onClick={openBugReport}>
                <div className="icon">
                    <img src={BUG_ICON} alt="bug" />
                    <div className="bg"> </div>
                </div>
                <div className="details">
                    <p> Report an Issue </p>
                    <p className="blue"> Help us improve Inward Strong </p> 
                    <img src={ARROW_ICON} alt="arrow-icon" />
                </div>
            </div>


            <div className="Settings-Details second" onClick={openHelpReport}>
                <div className="icon">
                    <img src={HELP_ICON} alt="help" />
                    <div className="bg"> </div>
                </div>
                <div className="details">
                    <p> Having Trouble? </p>
                    <p className="blue"> We are here to help </p> 
                    <img src={ARROW_ICON} alt="arrow-icon" />
                </div>
            </div>


            <div className="Settings-Details third" onClick={openTermsOfUse}>
                <div className="icon">
                    <img src={TERMS_ICON} alt="help" />
                    <div className="bg"> </div>
                </div>
                <div className="details">
                    <p> Terms of Use </p>
                    <p className="blue"> Rules of Membership </p> 
                    <img src={ARROW_ICON} alt="arrow-icon" />
                </div>
            </div>


            <div className="Settings-Details third" onClick={openPrivacyPolicy}>
                <div className="icon">
                    <img src={LOCK_ICON} alt="help" />
                    <div className="bg"> </div>
                </div>
                <div className="details">
                    <p> Privacy Policy </p>
                    <p className="blue"> Your privacy is important to us </p> 
                    <img src={ARROW_ICON} alt="arrow-icon" />
                </div>
            </div>


            <div className="Button-Container">
                <LinkButton name="Log Out" className="logout" onButtonClick={logout} />
            </div>

        </div>
    )
}

export default StoreConnect(Settings)
