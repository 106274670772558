
import HELP_ICON from "./assets/help-self.svg";
import MENTAL_HEALTH_ICON from "./assets/mental-health-line.svg";
import SERVICE_ICON from "./assets/service-line.svg";
import TEAM_ICON from "./assets/team-line.svg";

export default Object.freeze({
    goals: [
        {
            id: "goal-1",
            name: "Help Yourself",
            pictureURL: HELP_ICON
        },
        {
            id: "goal-2",
            name: "Support Others",
            pictureURL: SERVICE_ICON
        },
        {
            id: "goal-3",
            name: "Mental Fitness",
            pictureURL: MENTAL_HEALTH_ICON
        },
        {
            id: "goal-4",
            name: "Community Giveback",
            pictureURL: TEAM_ICON
        }
    ]
});