import React from "react";
import { Route } from 'react-router-dom';
import GettingStarted from "../../screens/GettingStarted";
import Home from "../../screens/Home";

/**
 * Allows only authenticated user to these screen, else route to default page
 * @param {} props 
 * @returns 
 */
function AuthRoute(props){

    const { user, path, component } = props

    return(
        <>
            { user ? <Route exact path={path} component={component} /> : <Route exact path={path} component={GettingStarted} /> }
        </>
    )
}

/**
 * Allow on unauthenticated users to these screens, else route to home page
 * @param {} props 
 * @returns 
 */
function UnAuthRoute(props){

    const { user, path, component } = props

    return(
        <>
            { user ? <Route exact path={path} component={ Home } /> : <Route exact path={path} component={component} /> }
        </>
    )
}

export { AuthRoute, UnAuthRoute }