import React from "react";
import PropTypes from "prop-types";
import ISButton from "../button"
import "./index.css";


function AssessmentCard( { assessment, onAssessmentStart } ){

    const onButtonClick = () =>{
        onAssessmentStart( assessment )
    }

    return(
        <div className="AssessmentCard">
            <h3> { assessment.name } </h3>
            <ISButton value="Start Assessment" onClick={onButtonClick} />
        </div>
    )
}

AssessmentCard.propTypes = {
    assessment: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string
    }),
    onAssessmentStart: PropTypes.func
}

export default AssessmentCard;