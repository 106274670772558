import React, { useState, useEffect, useRef } from "react"
import ISButton from "../../components/button";
import { getAuth, updateEmail, updateProfile, EmailAuthProvider, signInWithCredential, reauthenticateWithCredential } from "firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { getISUserProfile, updateISUserDisplayName, updateISUserDisplayStatus } from "../../shared/middleware";
import { createMessageModal, createPasswordVerificationModal } from "../../shared/utils/modals";
import LOCK_ICON from "./assets/lock.png";
import SUCCESS_IMAGE_ICON from "../../app/assets/checkbox-circle-line.svg";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import INFO_ICON from "../Home/assets/information-line.svg";
import "./index.css";

function Profile({dispatch}){

    const [ auth ] = useState(getAuth())
    const [ userProfile, setUserProfile ] = useState(null);
    const [ allowPasswordChange, setAllowPasswordChange ] = useState(false);
    const [ shouldUpdateEmail, setShouldUpdateEmail ] = useState(false);
    const [ shouldUpdateName, setShouldUpdateName ] = useState(false);
    const [ shouldUpdateDisplayStatus, setShouldUpdateDisplayStatus ] = useState(false);
    const [ buttonState, setButtonState ] = useState(true);
    const email = useRef();
    const displayName = useRef();
    const goal = useRef();
    const passwordRef = useRef();

    useEffect(() =>{
        dispatch( updateISHeader(true, null, null) )
    }, [ dispatch ])

    useEffect(()=>{

        if ( auth.currentUser ) {

            if ( auth.currentUser.providerData && auth.currentUser.providerData.length > 0 ) {
                let passwordProvider = auth.currentUser.providerData.find( (provider) => {
                    return (provider.providerId.toLowerCase() === "password")
                })

                passwordProvider ? setAllowPasswordChange(true) : setAllowPasswordChange(false);
            }

            getISUserProfile( auth.currentUser.uid )
                .then(( userProfileSnapshot ) => {
                    setUserProfile( userProfileSnapshot.val() );
                });
        }

    }, [ auth ])

    const onDisplayNameChange = () => {
        setButtonState(false);
        setShouldUpdateName(true);
    }

    const onEmailChange = () => {
        setButtonState(false);
        setShouldUpdateEmail(true);
    }

    const onDisplayStatusChange = () => {
        setButtonState(false);
        setShouldUpdateDisplayStatus(true)
    }

    const saveProfileChanges = () => {

        if ( shouldUpdateEmail ) {
            updateEmail( auth.currentUser, email.current.value )
                .then(()=>{
                    setButtonState(true);
                })
                .catch((err) => {

                    if ( err.message.includes("auth/requires-recent-login)") ){
                        dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createPasswordVerificationModal( "Verify your password", INFO_ICON, "Verify", verifyPassword, passwordRef) } })
                    }

                })
        }

        if ( shouldUpdateName ) {
            updateISUserDisplayName( auth.currentUser.uid, displayName.current.value )
                .then( () =>{
                    return updateProfile(auth.currentUser, { displayName: displayName.current.value })
                })
                .then(() => {
                    setButtonState(true);
                })
        }

        if (shouldUpdateDisplayStatus) {
            updateISUserDisplayStatus( auth.currentUser.uid, goal.current.value )
                .then(()=>{
                    setButtonState(true);
                })
        }

    }

    const verifyPassword = () => {

        let authCredential = EmailAuthProvider.credential( auth.currentUser.email, passwordRef.current.value );

        signInWithCredential(auth, authCredential)
            .then((userCredential) => {
                return reauthenticateWithCredential(userCredential.user, authCredential)
            })
            .then((userCredential) => {
                return updateEmail( userCredential.user, email.current.value )
            })
            .then(() => {
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Success", SUCCESS_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Your email has been updated" ) } })
            })
            .catch((err) => {
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Please check your password and try again.") } })
            })
    }

    return (
        <div className="Profile-Container">
            <h2> Your Profile</h2>

            { userProfile ?    
                <div className="UserName InputField First">
                    <p> What are you looking to get out of your Inward Strong journey? </p>
                    <input type="text" ref={goal} id="goals" name="goals" defaultValue={userProfile.displayStatus ? userProfile.displayStatus : ""} onChange={onDisplayStatusChange} />
                </div> : null }

            <hr />
            
            { userProfile ? 
                <div className="UserName InputField">
                    <p> Your Sponsor </p>
                    <div className="lockedInput">
                        <input type="text" id="sponsorEmail" name="sponsorEmail" placeholder={userProfile.sponsorEmail ? userProfile.sponsorEmail : "InwardStrong"} readOnly />
                        <img src={LOCK_ICON} alt="lock" />
                    </div>
                </div> : null }

            { userProfile ? 
                <div className="Terms">
                    <a href="/#/ConfirmSponsor/update" style={{position: "relative", bottom: "3px", left: "4px"}}> Update your sponsor </a>
                </div> : null }

            <hr />

            { auth && auth.currentUser ?
                <div className="UserName InputField">
                    <p> Enter your personal E-mail address</p>
                    { allowPasswordChange ?
                         <input ref={email} type="text" id="email" name="email" defaultValue={auth.currentUser.email} onChange={onEmailChange} /> : 
                         <input type="text" id="email" name="email" placeholder={auth.currentUser.email} readOnly /> }
                </div> : null }

            { userProfile ? 
                <div className="UserName InputField ReducedMarginTop">
                    <p> What should we call you?</p>
                    <input ref={displayName} type="text" id="displayName" name="displayName" defaultValue={userProfile.displayName} onChange={onDisplayNameChange} />
                </div> : null }

            { allowPasswordChange ? 
                <div className="Terms">
                    <a href="/#/UpdatePassword" style={{position: "relative", bottom: "3px", left: "4px"}}> Change your password </a>
                </div> : null }

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Save" onClick={saveProfileChanges} /> 
            </div>

            <div className="Terms Center">
                <a href="/#/ReportIssue/delete" style={{position: "relative", bottom: "3px", left: "4px"}}> Delete your account </a>
            </div> 

        </div>
    )
}

export default StoreConnect(Profile);