import React from "react";
import ISButton from "../button";
import "./index.css"

function FeaturedSession({ module, onStartSession }){


    const onActionClick = () => {
        onStartSession( module );
    } 

    return (
        <div className="FeaturedContainer">
            { module ? 
                <>
                    <div className="FeaturedSession">
                        <img className="SessionArtwork" src={module.artwork} alt="module artwork" />

                        <div className="Details">
                            <h4> { module.title } </h4>
                            <p> { module.description } </p>
                        </div>
                    </div>

                    <div className="ActionContainer">
                        <ISButton value="Start Session" onClick={onActionClick} />
                    </div>
                </>
            : null }
        </div>
    )

}

FeaturedSession.defaultProps = {
    module: {
        title: "Stress - Friend or Foe",
        description: "In this session, you’ll discover how to effectively navigate and understand the role of stress in your life, and how you can",
        artwork: "https://images.ctfassets.net/kh8ymnzeu1z1/2fyWwbT5atNY7lu0nUB4QT/e399a22fb728a1258b855d52edaaff9f/Rectangle_37.png?h=250"
    }
}

export default FeaturedSession