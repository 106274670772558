import React, { useEffect, useRef, useState } from "react";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { getFromLocalStorage, removeFromLocalStorage } from '../../shared/utils/localStorage';
import { createMessageModal } from "../../shared/utils/modals";
import { updateISHeader, createLoginAction } from "../../shared/utils/dispatchHelpers";
import winObj from "../../shared/utils/window";
import { createISUserProfile } from "../../shared/middleware";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import ERRORS from '../../app/constant';
import "./index.css";

var { AUTH_ERRORS } = ERRORS;

function CreateAccount( { dispatch } ){

    const checkBoxRef = useRef();
    const email = useRef();
    const password = useRef();
    const confirmPassword = useRef();
    const [buttonState, setButtonState] = useState(true);

    useEffect(()=>{
        dispatch(updateISHeader(true, false, createLoginAction()))
    }, [dispatch])

    /* Event Handlers */
    function onCheckBoxClick(){
        setButtonState(!checkBoxRef.current.checked);
    }

    function onCreateAccountClick(){

        const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailValue = email.current.value;
        let passwordValue = password.current.value;
        let confirmPasswordValue = confirmPassword.current.value;

        if ( emailValue === "" || passwordValue === "" || confirmPasswordValue === "" ){
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.CREATE_ACCOUNT_ERRORS["auth/empty-value"]) } })
            return;
        }

        // check if email is good
        if (!emailRegExp.test(emailValue)){
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.CREATE_ACCOUNT_ERRORS["auth/invalid-email"]) } })
            return;
        }

        // check if password match
        if ( passwordValue !== confirmPasswordValue ){
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.CREATE_ACCOUNT_ERRORS["auth/password-not-match"]) } })
            return;
        }

        // Create account
        dispatch({ type: 'LOADER', data: { play: true } });
        createUserWithEmailAndPassword(getAuth(), emailValue, passwordValue)
        .then((userCredential) => {
            const user = userCredential.user
            let dpName = getFromLocalStorage("displayName");

            logAnalyticEvent(Events.SIGN_UP, createEventData({ method: "email" }) );

            createISUserProfile( user.uid, dpName )
                .then( ()=>{
                    updateProfile(user, { displayName: dpName })
                })
                .finally(()=>{
                    removeFromLocalStorage("displayName");
                    dispatch({ type: 'LOADER', data: { play: false } });
                    winObj.location.href = "/";
                })
        })
        .catch((error) => {
            let errMessage = AUTH_ERRORS.CREATE_ACCOUNT_ERRORS[error.code];
            if ( !errMessage ) errMessage = "Something went wrong. Please check credentials and try again"
            dispatch({ type: 'LOADER', data: { play: false } });
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, errMessage ) } })
        });

    }

    return (
        <div className="CreateAccount-Container">
            <h2> Welcome, </h2>
            <h4> What's your password? </h4>

            <div className="UserName InputField">
                <p> Enter your email address </p>
                <input type="text" ref={email} id="username" name="username" />
            </div>

            <div className="Password InputField">
                <p> Enter your password </p>
                <input type="password" ref={password} id="password" name="password" />
            </div>

            <div className="Password InputField">
                <p> Re-enter your password </p>
                <input type="password" ref={confirmPassword} id="password-confirm" name="password-confirm" />
            </div>

            <div className="Terms">
                <input type="checkbox" id="terms" name="terms" ref={checkBoxRef} onClick={onCheckBoxClick} />
                <a href="https://www.inwardstrong.com/terms-of-use" target="_blank" rel="noreferrer" style={{position: "relative", bottom: "3px", left: "4px"}}> I agree to the Terms of Service </a>
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Submit" onClick={onCreateAccountClick} /> 
            </div>

        </div>
    )
}

export default StoreConnect(CreateAccount);