import React from "react";
import ARROW_IMG from "./assets/Vector.png"

function Arrow(props) {

    const { className, onClick } = props;

    return (
        <div className={className} onClick={onClick} >
            <img className="icon" src={ARROW_IMG} alt="arrow" />
        </div>
    );
}

export default Arrow;