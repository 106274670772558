import React from "react";
import PropTypes from "prop-types";
import "./index.css";

function ScoreCard( { card, onCardSelected } ){

    const onCardClick = () => {
        if ( onCardSelected ) onCardSelected( card )
    }

    return (
        <div className="ScoreCard" onClick={onCardClick}>
            <h6> { card.date } </h6>
            { card.firstScore ? 
                <div className="Score">
                    <p className="value"> { card.firstScore } </p>
                    <p className="label"> { card.firstScoreLabel } </p>
                </div> 
            : null }
            { card.secondScore ?
                <div className="Score">
                    <p className="value"> { card.secondScore } </p>
                    <p className="label"> { card.secondScoreLabel } </p>
                </div>
            : null }
        </div>
    )
}

ScoreCard.propTypes = {
    card: PropTypes.shape({
        date: PropTypes.string,
        firstScore: PropTypes.number,
        firstScoreLabel: PropTypes.string,
        secondScore: PropTypes.number,
        secondScoreLabel: PropTypes.string,
    }).isRequired,
    onCardSelected: PropTypes.func
}

export default ScoreCard;