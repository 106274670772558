import React from "react";
import PropTypes from "prop-types";
import "./index.css";

function Chip({ name, isSelected, onSelected }){

    const onChipSelected = () =>[
        onSelected( { selected: !isSelected, name } )
    ]

    return (
        <div className={ isSelected ? "chip selected " : "chip" } onClick={onChipSelected}>
            <p> { name } </p>
            { /* isSelected ? <img className="icon" src={CLOSE_ICON} alt="close" /> : null  */}
        </div>
    )
}

Chip.propTypes = {
    name: PropTypes.string,
    isSelected: PropTypes.bool,
    onSelected: PropTypes.func
}

Chip.defaultProps = {
    name: "",
    isSelected: false
}

export default Chip;