import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Carousel from "react-slick";
import Chip from "../chip";
import Arrow from "../ISCarousel/arrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: false,
    variableWidth: true,
    responsive: [
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3
          }
        }
    ],
    nextArrow: <Arrow />,
    prevArrow: <Arrow />
}

function FilterChips( { title, filters, onChipsSelected } ){

    const [ items, setItems ] = useState([]);

    useEffect(()=>{
        setItems(filters.map( (filter) => { return { ...filter, isSelected: false } } ));
    }, [ filters ])

    const onChipSelected = (chip) => {
        let selectedChipIndex = items.findIndex((item)=>item.name === chip.name)

        if ( selectedChipIndex > -1 ){
            let updatedItems = [ ...items ]
            updatedItems[ selectedChipIndex ] = { ...items[ selectedChipIndex ], isSelected: chip.selected }
            setItems( updatedItems );
            onChipsSelected( updatedItems );
        }
    }

    return (
        <div className="FilterChips">

            <h3> { title } </h3>

            <Carousel {...settings}>
                { items.map( ( item, index ) =>
                    <Chip key={index} name={item.name} isSelected={item.isSelected} onSelected={onChipSelected} />
                )}
            </Carousel>

        </div>
    )
}

FilterChips.propTypes = {
    title: PropTypes.string,
    filters: PropTypes.array.isRequired,
    onChipsSelected: PropTypes.func
}

FilterChips.defaultProps = {
    title: ""
}

export default FilterChips;