/* Helper methods to complete Assessment Transactions */

/**
 * Returns the tallied 
 * @param {Object} quiz - Question No and Answer Choice
 * @return {Int} Total Score for the quiz
 */
function tallyScores( quiz ) {
    return quiz.length > 0 ? quiz.reduce((prevValue, currValue) => prevValue + currValue , 0) : 0;
}


/**
 * Method returns the score label based on score
 * @param {Int} score 
 * @return {String} label
 */
function getScoreLabel(score){
    let severity = "Severe";

    if (score <= 4) {
        severity = "Minimal"
    } else if ( score >= 5 && score <= 9) {
        severity = "Mild"
    } else if ( score >= 10 && score <= 14 ) {
        severity = "Moderate"
    }

    return severity;
}

export { tallyScores, getScoreLabel };