import INFO_ICON from "../../screens/Home/assets/information-line.svg";
import USER_CIRCLE_ICON from "../../app/assets/user.svg";
import TOKENS_ICON from "../../app/assets/trophy.svg";
import FAV_ICON from "../../app/assets/heart.svg";
import { rearrangeMyCarepathway, updateMyCarePathway, updateMyCurrentSession, startSession } from "../middleware";
import { createMessageModal } from "./modals";
import winObj from "./window";

/**
 * Update the header bar on top of the page
 * @param {Bool} allowBackNav 
 * @param {String} logo 
 * @param {Object} action 
 * @param {Object} secondaryAction
 * @returns {Object} State object for header
 */
 function updateISHeader(allowBackNav = false, logo = false, action = null, secondaryAction = null, tertiaryAction = null ){
    return { type: 'HEADER', data: { logo, allowBackNav, action, secondaryAction, tertiaryAction } };
}

/**
 * Renders the logo again on screen
 */
function renderLogo(){
    return { type: 'LOGO', data: { reRender: true } }
}

/**
 * Creates a login action 
 * @returns {Object} Login action object
 */
function createLoginAction(){
    return { name: "Login", onActionClick: ()=>{
        winObj.location.href = "/#/Login"
    }}
}

/**
 * Skip All action menu
 * @param { func } onAction - optinal button
 * @returns Creates a skip all action object
 */
function createSkipAllAction( onAction = null ){
    return { name: "Skip All", onActionClick: ()=>{
        if ( onAction ) onAction();
        winObj.location.href = "/#/Home"
    }}
}

/**
 * Skip a Module action button
 * @param {object} dispatch
 * @param {object} session object
 * @param {object} currentCarePathway array
 * @returns Creates a skip module action button
 */
 function createSkipModuleAction( dispatch, currentSession, currentCarePathway, user, programSlug ){
    return { name: "Skip Module", onActionClick: ()=>{

        const okayHandle = () =>{

            let currentSessionIndex = currentCarePathway.findIndex(( sessionSlug ) => sessionSlug === currentSession.slug )
            let nextSessionIndex = ( (currentSessionIndex + 1) < currentCarePathway.length ) ? currentSessionIndex + 1 : 0;

            updateMyCarePathway( user.id, rearrangeMyCarepathway(currentSession, 'skip', currentCarePathway), programSlug)
                .then(() => updateMyCurrentSession( user.id, currentCarePathway[nextSessionIndex], programSlug ))
                .then(()=>startSession( user, { slug: currentCarePathway[nextSessionIndex] }, programSlug ))
                .finally(()=>{
                    dispatch({ type: 'POP_UP_MODAL', data: { isOpen: false } })
                    winObj.location.href = "/#/Home"
                })
        }

        const message = "Would you like to skip this introduction? <br><br> <b> It will be placed at the end of your care pathway for you to revisit. </b>"

        let modalMessage = createMessageModal("Are you sure", INFO_ICON, "Yes, I'm sure", okayHandle, message)
        dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modalMessage } } )
    }}
}

/**
 * A link button redirection to settings page
 * @returns Link Object
 */
function createSettingsAction(){
    return { name: "", icon: USER_CIRCLE_ICON, onActionClick: ()=>{
        winObj.location.href = "/#/Settings"
    }}
}

/**
* A link button redirection to tokens of gratitude
* @param { int } total points
* @returns Link Object
*/
function createPointsLink( totalPts ){
    return { name: totalPts + "", icon: TOKENS_ICON, onActionClick: ()=>{
        winObj.location.href = "/#/Tokens"
    }}
}

/**
* Creates a link button to the favourites
* @returns Link Object
*/
function createFavsLink(){
    return { name: "", icon: FAV_ICON, onActionClick: ()=>{
        winObj.location.href = "/#/Favourites"
    }}
}



export { updateISHeader, createLoginAction, createSkipAllAction, createSkipModuleAction, createSettingsAction, createPointsLink, renderLogo, createFavsLink }