
/** Firebase config settings */
export default Object.freeze({
    apiKey: "AIzaSyAcvGPzr9m4qnwO7_shjnnP16i21bfFnLw",
    authDomain: "app.inwardstrong.com",
    databaseURL: "https://inwardstrong-e017e-default-rtdb.firebaseio.com",
    projectId: "inwardstrong-e017e",
    storageBucket: "inwardstrong-e017e.appspot.com",
    messagingSenderId: "973001371633",
    appId: "1:973001371633:web:6d1338fc1228ab26da1e50",
    measurementId: "G-1P99EJ9BK5"
  });