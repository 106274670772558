import React, { useEffect, useState } from "react";
import Favourite from "../../components/favourite";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import awardPoints from "../../shared/utils/rewards";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { getArticleByID, isItemFavourited, addToFavourites, removeFromFavourites, CONTENT_TYPES } from "../../shared/middleware";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import "./index.css";

function ArticleScreen( { dispatch, match, store } ){

    const iStyle = { border: "none" }
    const { articleID } = match.params

    const [ article, setArticle ] = useState(null);
    const [ articleInFavs, setArticleInFavs ] = useState(false);

    useEffect(()=>{
        let awardTimer = null;
        dispatch({ type: "FOOTER", data: { display: false } })

        if ( store.userProfile ) {
            awardTimer = setTimeout(() => {
                const updatedtokens = awardPoints( store.userProfile.id, 'ARTICLE_VIEW', store.tokens, store.userProfile.orgSponsor );
                dispatch( { type: 'TOKENS', data: updatedtokens } )
            }, 15000);
        }

        return (()=>{
            dispatch({ type: "FOOTER", data: { display: true } })
            if ( awardTimer ) clearTimeout(awardTimer);
        })
        
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        dispatch(updateISHeader(true, null))
    },[dispatch])


    useEffect(() => {

        getArticleByID( articleID )
            .then( (article) => {
                if ( store.userProfile ) logAnalyticEvent(Events.SELECT_CONTENT, createEventData({ content_type: "Article", item_id: article.title, sponsoring_org: store.userProfile.orgSponsor }));
                setArticle(article)
             })

        if ( store.userProfile ) setArticleInFavs( isItemFavourited( store.userProfile, CONTENT_TYPES.ARTICLE, { articleID } ) )
             
    // eslint-disable-next-line        
    }, [articleID])


    const onFavouriteSelected = ( set, e ) => {

        e.stopPropagation();
        setArticleInFavs(set);
        let favs = ( set ) ? addToFavourites( store.userProfile, CONTENT_TYPES.ARTICLE, { articleID } ) : removeFromFavourites( store.userProfile, CONTENT_TYPES.ARTICLE, { articleID } );

        favs.then(( userProfile ) => {
            dispatch( { type: 'USER', data: userProfile } );
        })
    }


    return(
        <div className="Article-Container">

            { store.userProfile ? <Favourite set={articleInFavs} onClick={onFavouriteSelected} /> : null }


            { article ? 
                <iframe key={article.referenceLink} title="toolbox" src={article.referenceLink} style={iStyle} />
            : null }
        </div>
    )
}

export default StoreConnect(ArticleScreen)