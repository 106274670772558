import React, { useEffect } from "react";
import { getAuth } from "firebase/auth"
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { getISUserProfile } from "../../shared/middleware";
import winObj from '../../shared/utils/window';

function Root({ dispatch }){

    useEffect(()=>{

        const auth = getAuth()
        const user = auth.currentUser

        if (user) {

            getISUserProfile( user.uid )
              .then(( snapshot )=>{
                const ISUserProfile = snapshot.val();

                if ( ISUserProfile && ISUserProfile.orgSponsor ){
                  winObj.location.href = "/#/Home";
                } else {
                  winObj.location.href = "/#/ConfirmSponsor/signup";
                }
              })
              .catch(()=>{
                winObj.location.href = "/#/Home";
              })

          } else if (getPasswordResetCode(winObj.location)) {
            winObj.location.href = "/#/ConfirmPassword/" + getPasswordResetCode(winObj.location)
          } else {
            winObj.location.href = "/#/GetStarted";
          }

    // eslint-disable-next-line
    }, [])

    return (
        <>
        </>
    )
}

export default StoreConnect(Root);

/** Helpers */

/**
 * Route page to password reset
 * @param { object } location
 * @return { String } Code or null
 */
 function getPasswordResetCode( location ){

    const urlParams = new URLSearchParams(location.search);
  
    if ( urlParams.has('mode') && urlParams.has('oobCode') && urlParams.get('mode') === 'resetPassword' ) {
      return urlParams.get('oobCode')
    }
  
    return null
  }