import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { isMobile, isAndroid } from "react-device-detect";
import { StoreConnect } from "../HOC/StoreConnector";
import winObj from "../../shared/utils/window";
import { createInstructionModal } from "../../shared/utils/modals";
import GoogleBadge from "./assets/google-play.png";
import ApplePin from "./assets/apple-pin.png";
import IS_ICON from "./assets/IS-Icon.png";
import InstallPromptConstants from "./constants"
import "./installPrompt.css"

/**
 * A component, that provides an interface for the user to install the app on their device.
 * @returns 
 */
function InstallAppPrompt( props ){

    const [ showInstallPrompt, setShowInstallPrompt ] = useState(isMobile);
    const [ installBoxStyle, setInstallBoxStyle ] = useState({ display: "block" })
    const [position, setPosition] = useState(props.position);
    let installPrompt = useRef(null);

    useEffect(()=>{

        // Set up Event Listeners
        if ( winObj && isMobile ) {
            winObj.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                installPrompt.current = e;
                setShowInstallPrompt(true);
            })

            winObj.addEventListener('appinstalled', ()=>{
                setShowInstallPrompt(false);
                installPrompt.current = null;
            })
        
            if (isAppInstalled()){
                setShowInstallPrompt(false);
                installPrompt.current = null;
            }
        }

    }, [])

    useEffect(()=>{
        setInstallBoxStyle( {
            display: showInstallPrompt ? "block" : "none"
        })
    }, [showInstallPrompt])

    useEffect(()=>{
        setPosition( props.position === InstallPromptConstants.POSITIONS.top ? "top" : "" )
    }, [ props.position ])

    /* Event Handlers */

    const onNotificationDismiss = () =>{
        setShowInstallPrompt(false);
    }

    const addAppToHomescreen = () => {
        if ( installPrompt.current ) { 
            installPrompt.current.prompt(); 
        } else {
            props.dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createInstructionModal( "Install the Inward Strong app to your home screen:", IS_ICON, "Okay", ()=>{props.dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}) } })
        }
    }

    function isAppInstalled() {
        return (winObj.matchMedia('(display-mode: standalone)').matches);
    }

    return (<>
        <div className={"InstallPrompt-Box " + position} style={installBoxStyle} >
            <div className="closeIcon" onClick={onNotificationDismiss} ></div>
            <div className="contents">
                <p> Download the App </p>
                { isAndroid ? <img src={GoogleBadge} alt="Download the app" onClick={addAppToHomescreen} /> : <img className="iOS"  src={ApplePin} alt="Download the app" onClick={addAppToHomescreen}  /> }
            </div>
        </div>
    </>)
}

InstallAppPrompt.propTypes = {
    position: PropTypes.string
}

InstallAppPrompt.defaultProps = {
    position: "bottom"
}

export default StoreConnect(InstallAppPrompt);