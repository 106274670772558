import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { getAuth } from "@firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISCarousel from "../../components/ISCarousel";
import StressLogScale from "../../components/StressLogScale";
import ScoreCard from "../../components/scoreCard";
import awardPoints from "../../shared/utils/rewards";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { getAllArticles, addStressCheckIn, getISUserProfile, getStressCheckinScales } from "../../shared/middleware";
import { createScoreCardDetailsModal } from "../../shared/utils/modals";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import "./index.css"

const DATE_TIME_FORMAT = "yyyy-LL-dd:HH";

function StressCheckin( { dispatch, store } ){

    const [ auth ] = useState(getAuth());
    const [ userProfile, setUserProfile ] = useState(null);
    const [ articles, setArticles ] = useState(null);
    const [ scaleType, setScaleType ] = useState("stress");
    const [ scores, setScores ] = useState([]);
    const [ stressScore, setStressScore ] = useState(1);
    const [ stressScaleValues, setStressScaleValues ] = useState({});

    useEffect(() => {

        getAllArticles( [ "stress", "anxiety" ] )
            .then((posts) => {
                setArticles( posts.length > 0 ? posts.map(convertArticleIntoCarouselModel) : [] )
            })

        getStressCheckinScales()
            .then(( data ) => {
                if ( data ) setStressScaleValues( data );
            })

    }, []);

    useEffect(() => {
        dispatch(updateISHeader(true, null));
    }, [dispatch])

    useEffect(() => {

        if ( auth.currentUser ) {
            getISUserProfile( auth.currentUser.uid )
                .then( ( userProfileSnapshot ) => {
                    let ISUserProfile = userProfileSnapshot.val();
                    setUserProfile( ISUserProfile )
                })
        }

    }, [auth])

    useEffect( () => {

        if ( userProfile && userProfile.stressCheckIn && stressScaleValues && stressScaleValues.logValues ){
            setScores( createStressScoreCards( Object.entries(userProfile.stressCheckIn).sort( sortByLatestCheckIns ), stressScaleValues ) )
        }

    }, [ userProfile, stressScaleValues ] )

    const onScaleResult = ( result ) =>{
        
        addStressCheckIn( userProfile.id, DateTime.utc().toFormat(DATE_TIME_FORMAT), { type: result.type, score: result.scaleValue } )
            .then(()=>{
                return getISUserProfile( userProfile.id )
            })
            .then((userProfileSnapshot)=>{
                let ISUserProfile = userProfileSnapshot.val();
                setUserProfile( ISUserProfile )
            })

        logAnalyticEvent( Events.STRESS_SCALE_LOG, createEventData( { stress_scale_type:result.type, stress_scale_score: getStressResultLabel( result.type, result.scaleValue, stressScaleValues ), sponsoring_org: userProfile.orgSponsor } ) )
        
        let taskName = ""

        if ( scaleType === "stress" ) {
            setStressScore( result.scaleValue );
            taskName = 'LOG_STRESS';
            setScaleType("coping") 
        }   
        else {
            let scoreCardDetailsModel = createScoreCardDetailModel( DateTime.local(), stressScore, result.scaleValue, stressScaleValues );
            let modal = createScoreCardDetailsModal(scoreCardDetailsModel)
            dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modal } } )

            taskName = 'LOG_COPING'
            setStressScore(1);
            setScaleType("stress");
        }

        const updatedtokens = awardPoints( userProfile.id, taskName, store.tokens, userProfile.orgSponsor );
        dispatch( { type: 'TOKENS', data: updatedtokens } )

    }

    const onCardClick = ( card ) => {
        let scoreCardDetailsModel = createScoreCardDetailModel( DateTime.fromFormat(card.date, "DD").toLocal(), card.firstScore, card.secondScore, stressScaleValues );
        let modal = createScoreCardDetailsModal(scoreCardDetailsModel)
        dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modal } } )
    }


    return (
        <div className="Stress-Container">

            { stressScaleValues && stressScaleValues.logValues ? <StressLogScale type={scaleType} onResult={onScaleResult} scaleValues={stressScaleValues} /> : null }

            { articles ? <ISCarousel title="Tools & Techniques" cards={articles} /> : null }

            { scores && scores.length > 0 ? 
            <div className="ScoreContainer">
                <h3> History </h3>
                { scores.map((scorecard, index) => 
                    <ScoreCard key={index} card={scorecard} onCardSelected={onCardClick} />
                )}
            </div> 
            : null }

        </div>
    )

}

export default StoreConnect(StressCheckin);

/**
 * Converts articles into carousel card models
 * @param {object} article 
 * @returns {object} card models
 */
function convertArticleIntoCarouselModel( article ) {
    return {
        title: article.title,
        link: "/#/Article/" + article.id,
        artwork: article.artwork,
        isLocked: false,
        flagText: ""
    }
}


/**
 * Creates scorecards based on stress check-in results
 * @param {Object} stressCheckIns objects by date/hr format 
 * @return {Object} scorecard objects
 */
function createStressScoreCards( stressCheckIns, stressScaleValues ){
    return stressCheckIns.map( ( [ checkInDateHr, results ]) => {
        return {
            date: DateTime.fromFormat(checkInDateHr, DATE_TIME_FORMAT).toLocal().toFormat("DD"),
            firstScore: ( results.stress ? parseInt(results.stress.score) : null ),
            firstScoreLabel: ( results.stress ? stressScaleValues?.logValues["stress"][results.stress.score].rate + " Stress" : null ),
            secondScore: ( results.coping ? parseInt(results.coping.score) : null ),
            secondScoreLabel: ( results.coping ? stressScaleValues?.logValues["coping"][results.coping.score].rate + " Coping" : null ),
        }
    })
}

/**
 * Creates a scorecard details model
 * @param {object} dateTime in local
 * @param {int} stressScore 
 * @param {int} copingScore 
 * @returns 
 */
function createScoreCardDetailModel( dateTime, stressScore, copingScore, stressScaleValues ){
    return {
        date: dateTime.toFormat('ccc, d LLL y'),
        scores: [ {
            scoreTitle: "Score: " + stressScore + " " + stressScaleValues?.logValues["stress"][stressScore.toString()].rate + " Stress",
            scoreDescription: stressScaleValues?.results["stress"][stressScore]
        },
        {
            scoreTitle: "Score: " + copingScore + " " + stressScaleValues?.logValues["coping"][copingScore.toString()].rate + " Coping",
            scoreDescription: stressScaleValues?.results["coping"][copingScore]
        } 
        ]
    }
}

/**
 * sorts the stress check-in entries by date
 * @param {*} aCheckIn 
 * @param {*} bCheckIn 
 * @returns 
 */
function sortByLatestCheckIns( aCheckIn, bCheckIn ){
    const [ aDateFormat ] = aCheckIn
    const [ bDateFormat ] = bCheckIn

    const aDateTime = DateTime.fromFormat(aDateFormat, DATE_TIME_FORMAT).toUTC();
    const bDateTime = DateTime.fromFormat(bDateFormat, DATE_TIME_FORMAT).toUTC();

    return bDateTime.toMillis() - aDateTime.toMillis();
}

/**
 * Return a score label for the given score based on the scale type
 * @param {String} type ( stress or coping type )
 * @param {Int} score score number
 * @param {Object} scaleValues Definition of scale log values
 * @return {String} Label equivalent for the given score 
 */
function getStressResultLabel( type, score, scaleValues ){
    return scaleValues?.logValues[ type.toLowerCase() ][ score.toString() ].rate;
}