import React from "react";
import PropTypes from "prop-types";
import "./linkButton.css";

function LinkButton({ icon, name, onButtonClick, className }){
    return(
        <button className={ className + " LinkButton"} type="button" onClick={onButtonClick} >
            { icon ? <img src={icon} alt="icon" /> : null }
            <p> { name } </p>
        </button>
    )
}

LinkButton.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onButtonClick: PropTypes.func
}

LinkButton.defaultProps = {
    icon: null,
    name: "",
    className: ""
}

export default LinkButton