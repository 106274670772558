import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import winObj from "../../shared/utils/window";
import "./popUpModal.css"

function PopUPModal({ isOpen, children, dispatch, onDismiss }){

    const [ displayStatus, setDisplayStatus ] = useState("none");

    useEffect(()=>{
        (isOpen) ? setDisplayStatus("block") : setDisplayStatus("none")

        if ( isOpen ) {
            winObj.document.body.style.overflowY = 'hidden';
        } else {
            winObj.document.body.style.overflowY = 'scroll';
        }

    }, [isOpen])

    const closeModal = (e) => {
        if ( onDismiss ) onDismiss();
        dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})
    }

    return(
        <div className="PopUPModal" style={ {"display": displayStatus} } >
            <div className="background" />
            <div className="modalContainer">
                <div className="closeIcon" onClick={closeModal} />
                { children }
            </div>
        </div>
    )
}

PopUPModal.propTypes = {
    isOpen: PropTypes.bool,
    dispatch: PropTypes.func,
    onDismiss: PropTypes.func,
}

export default PopUPModal;