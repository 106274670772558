import React, { useEffect, useRef, useState } from "react";
import { getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { createMessageModal } from "../../shared/utils/modals";
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { getFromLocalStorage, removeFromLocalStorage } from "../../shared/utils/localStorage";
import { getAllOrganizations, updateOrgSponsorForUser, getAllPrograms } from "../../shared/middleware";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import winObj from "../../shared/utils/window";
import "./index.css";

function ConfirmSponsor({dispatch, store, match}){

    const email = useRef();
    const history = useHistory();
    const { flowType } = match.params
    const [ organizations, setOrganizations ] = useState(null);
    const [ programs, setPrograms ] = useState(null);
    const [ couponCode, setCouponCode ] = useState(null);
    const [ couponOrg, setCouponOrg ] = useState(null);
    const [buttonState, setButtonState] = useState(true);

    useEffect(()=>{
        dispatch(updateISHeader(true, false))
    },[dispatch])

    useEffect(()=>{

        let cCode = getFromLocalStorage('coupon')
        if ( cCode ) setCouponCode( cCode )

        getAllPrograms()
            .then(( prgms ) => {
                setPrograms( prgms )
            })

        getAllOrganizations()
            .then( ( orgSnapshot )=>{
                setOrganizations( orgSnapshot.val() )
            })

    }, [])

    useEffect(() => {

        if ( organizations && couponCode ) {
            let orgCoupon = organizations.find( (org) => {
                if ( org && org.active && org.couponCode ) {
                    return org.couponCode === couponCode
                }
                return false;
            })

            if ( orgCoupon ) {

                console.log("Org Set Status", orgCoupon);

                setCouponOrg( orgCoupon )
                setButtonState(false);
                email.current.value = orgCoupon.name;
            }

        }

    }, [ organizations, couponCode ])

    const onEmailChange = ()=>{
        setButtonState(email.current.value.length === 0)
    }

    const onNext = ()=>{

        const auth = getAuth();

        let orgSponsor = organizations.find((org)=>{
            if ( org && org.active && org.emailSignature )
                return email.current.value.includes(org.emailSignature)
            return false;
        })

        if ( !orgSponsor && couponOrg ) {
            orgSponsor = couponOrg;
        }

        if ( orgSponsor ) {

            let program = programs.find(( program ) => {
                return (orgSponsor.defaultProgram && program.slug === orgSponsor.defaultProgram)
            })

            updateOrgSponsorForUser( auth.currentUser.uid, orgSponsor.id, email.current.value )
                .finally(()=>{
                    removeFromLocalStorage('coupon');
                    logAnalyticEvent(Events.COUPON_REMOVED, createEventData({ sponsoring_org: orgSponsor.id, coupon_code: couponCode, flow_type: flowType }));

                    if ( flowType !== "update" ) {
                        ( program && !program.requiresOnBoarding ) ? winObj.location.href = "/#/Home" : winObj.location.href = "/#/Welcome";
                    } else {
                        dispatch( { type: 'ROUTE_HISTORY', data: store.routeHistoryDepth - 1 } )
                        history.goBack();
                    }
                })
            
        } else {
            const message = "Sorry, it looks like that email domain is not connected to a registered sponsor. Please try again, or click here to prompt your company join Inward Strong.";
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, message ) } })
        }
    }

    const noSponsor = () =>{

        const auth = getAuth();

        logAnalyticEvent(Events.SKIP_SPONSOR, createEventData({ sponsoring_org: "1", coupon_code: couponCode, flow_type: flowType }));
    
        // InwardStrong as Sponsor
        updateOrgSponsorForUser( auth.currentUser.uid, "1", "InwardStrong" )
                .finally(()=>{
                    winObj.location.href = "/#/Welcome"
                })
    }

    return(
        <div className="ConfirmSponsor-Container">

            <h2> Welcome { store.userProfile && store.userProfile.displayName ? store.userProfile.displayName : ""}  </h2>
            <h4> { couponOrg ? "Your membership is being subsidized by a organization" : "Is your membership being subsidized by a company?" } </h4>

            <div className="UserName InputField">
                <p className="oversizedSmall"> { couponOrg ? "Sponsoring Organization is" : "Enter your company's email address" } </p>
                { couponOrg ? null : 
                    <p className="smallfont"> Your company email is used for verification purposes only, participation is confidential. </p> 
                }
                <input type="text" ref={email} id="username" name="username" onChange={onEmailChange} placeholder="you@company.com" readOnly={ couponOrg ? true : false } />
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Next" onClick={onNext} /> 
                { (!couponOrg && flowType === "signup" ) || (!couponOrg && flowType !== "update") ? 
                    <ISButton onClick={noSponsor} value="I'm not being subsidized" type="clear" />
                    : null }
            </div>

        </div>
    )
}

export default StoreConnect(ConfirmSponsor)