import React from "react";
import PropTypes from "prop-types";
import "./index.css";

function ProgressBar({ progressPercent }){
    return (
        <div className="ProgressBar">
            <div className="Indicator" style={{width: progressPercent + "%"}} />
        </div>
    )
}

ProgressBar.propTypes = {
    progressPercent: PropTypes.number
}

ProgressBar.defaultProps = {
    progressPercent: "0"
}

export default ProgressBar