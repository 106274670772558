import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Carousel from "react-slick";
import CarouselCard from "../carouselCard";
import settings from "./constant"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

/**
 * Displays a carousel with a title. 
 * @param {props} title and an array of cards
 */
function ISCarousel( { title, cards, onCardSelected, initialSlide } ){

    let carouselConfig = { ...settings, initialSlide };
    let carouselSlider = useRef();

    useEffect(()=>{
        if ( initialSlide && carouselSlider && carouselSlider.current ) {
            carouselSlider.current.slickGoTo(initialSlide);
        }
    }, [initialSlide])

    return (
        <div className="CarouselContainer">
            { cards.length > 0 ?
                <>
                    <h3> { title } </h3>

                    <Carousel ref={carouselSlider} { ...carouselConfig }>
                        { cards.map((card, index) => 
                            <CarouselCard key={index} card={card} onCardSelected={onCardSelected} />
                        )}
                    </Carousel>
                </>
            : null }

            
        </div>
    )
}

ISCarousel.propTypes = {
    title: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        artwork: PropTypes.string,
        link: PropTypes.string,
        isLocked: PropTypes.bool,
        flagText: PropTypes.string
    })),
    onCardSelected: PropTypes.func,
    initialSlide: PropTypes.number
}

ISCarousel.defaultProps = {
    title: "",
    cards: [],
    initialSlide: 0
}

export default ISCarousel