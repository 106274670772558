import winObj from "./window";

/**
 * Saves the item onto the local storage
 * @param {String} key 
 * @param {Object} value 
 */
function saveToLocalStorage(key, value){
    if ( winObj.localStorage ){
        localStorage.setItem(key, value);
    }
}

/**
 * Get Value from local storage
 * @param {String} key 
 * @return {Object} value or null
 */
function getFromLocalStorage(key){
    if ( winObj.localStorage ) {
        return localStorage.getItem(key)
    }
    return null;
}

/**
 * Removes item from local storage
 * @param {String} key 
 */
function removeFromLocalStorage(key){
    if (winObj.localStorage){
        localStorage.removeItem(key)
    }
}

export { saveToLocalStorage, getFromLocalStorage, removeFromLocalStorage }