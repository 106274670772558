import React, { useEffect, useReducer, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getRedirectResult, getAuth, onAuthStateChanged, updateProfile } from "firebase/auth"
import { StoreProvider } from '../components/HOC/StoreConnector';
import { AuthRoute, UnAuthRoute } from '../components/HOC/RouteConnector';
import ScrollToTop from '../components/scrollToTop';
import Logo from './../components/logo'
import Loader from './../components/loader'
import InstallAppPrompt from '../components/installAppPrompt';
import PopUPModal from '../components/popUpModal';
import LinkButton from '../components/LinkButton';
import Footer from '../components/footer';
import GettingStarted from '../screens/GettingStarted';
import SignUp from "../screens/SignUp";
import CreateAccount from '../screens/CreateAccount';
import Login from "../screens/Login";
import ForgotPassword from '../screens/ForgotPassword';
import ConfirmPassword from '../screens/ConfirmPassword';
import UpdatePassword from '../screens/UpdatePassword';
import ConfirmSponsor from '../screens/ConfirmSponsor';
import Home from "../screens/Home";
import WelcomeScreen from '../screens/WelcomeScreen';
import CarePathway from '../screens/CarePathway';
import Quiz from '../screens/Quiz';
import ArticleScreen from '../screens/ArticleScreen';
import SessionDetails from "../screens/SessionDetails";
import Tools from "../screens/Tools";
import Root from "../screens/Root";
import Settings from '../screens/Settings';
import Profile from '../screens/Profile';
import MindfulMinute from '../screens/MindfulMinute';
import StressCheckIn from '../screens/StressCheckIn';
import Tokens from '../screens/Tokens';
import ReportIssue from '../screens/ReportIssue';
import MoodTracker from '../screens/MoodTracker';
import FavouritesScreen from '../screens/FavouritesScreen';
import WeeklyTipScreen from "../screens/WeeklyTip";
import Version from "../screens/Version";
import { getFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } from '../shared/utils/localStorage';
import { createMessageModal } from "../shared/utils/modals";
import { createISUserProfile, getISUserProfile } from "../shared/middleware";
import useAppVisibility from "../shared/hooks/useAppVisibility";
import { getCookie, setCookie } from "../shared/utils/cookie";
import winObj from '../shared/utils/window';
import Events, { setAnalyticUserID, logAnalyticEvent, createEventData } from "../shared/utils/analytics";
import FIREBASE_CONFIG from './firebaseConfig';
import ERRORS from "./constant";
import FAILURE_IMAGE_ICON from "./assets/close-circle-line.svg";
import BACK_ICON from "./assets/Back.png";

import './App.css';

const { AUTH_ERRORS } = ERRORS;

function App() {

  const [ isAppReady, setIsAppReady ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState(null);
  const [store, dispatch] = useReducer(appStoreReducer, getInitialAppStoreState());
  let history = useHistory();
  const appVisibilityState = useAppVisibility( winObj );
  const [ prevVisibilityState, setPrevVisibilityState ] = useState(null);

  useEffect(()=>{

    // initialize firebase 
    initializeApp(FIREBASE_CONFIG);

    const auth = getAuth();
    // Check if the user has just signed in with federated accounts
    getRedirectResult( auth )
      .then((result) => {
        if (result) {

          let dpName = getFromLocalStorage("displayName");
          dpName = dpName ? dpName : " ";
          
          getISUserProfile( auth.currentUser.uid )
            .then(( snapshot )=>{
              const ISUserProfile = snapshot.val();

              if ( !ISUserProfile ){
                createISUserProfile( auth.currentUser.uid, dpName )
                  .then(()=>{
                    updateProfile(auth.currentUser, { displayName: dpName })
                      .then(()=>{
                        removeFromLocalStorage("displayName");
                      })
                      .finally(()=>{
                        logAnalyticEvent( Events.SIGN_UP, createEventData({ method: "social" }));
                        winObj.location.href = "/";
                      })
                  })
              } else {
                logAnalyticEvent( Events.LOGIN, createEventData({ method: "social" }));
                winObj.location.href = "/";
              }
            })
        }
      })
      .catch((error)=>{
        if ( AUTH_ERRORS.REDIRECT_ERRORS[ error.code ] ){
          dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{ dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.REDIRECT_ERRORS[ error.code ]) } });
        }
      })

    onAuthStateChanged(auth, (user) => {

      if ( user ) {
        getISUserProfile( user.uid )
          .then((snapshot) => {
            const ISUserProfile = snapshot.val();

            dispatch({ type: 'TOKENS', data: ( ISUserProfile && ISUserProfile.tokens) ? ISUserProfile.tokens : 0 });
            dispatch({type:'USER', data: ISUserProfile})

            setAnalyticUserID( user.uid );
            if ( ISUserProfile ) logAnalyticEvent( Events.USER_SETTINGS, createEventData({ user_id: user.id, sponsoring_org: user.orgSponsor }));

          })
          .finally(() => {
            setCurrentUser(user);
            setIsAppReady(true);
          })
      } else {
        setCurrentUser(user);
        setIsAppReady(true);
        dispatch({type:'USER', data: null})
      }

    });


    let couponCode = getCouponCode(winObj.location);
    if ( couponCode ) {
      logAnalyticEvent( Events.COUPON_SET, createEventData({ coupon_code: couponCode }));
      saveToLocalStorage( 'coupon', couponCode );
    }

  }, [])

  useEffect(()=>{

    if ( isAppReady ) {

      setTimeout(() => {
        dispatch({ type: 'LOADER', data: { play: false } });
        dispatch({ type: 'INSTALL_PROMPT', data: { position: "top" } });
        dispatch({ type: 'SPLASH', data: { display: "none" } });
      }, 1500)

    } else {
      dispatch({ type: 'LOADER', data: { play: true } });
      dispatch({ type: 'INSTALL_PROMPT', data: { position: "bottom" } });
      dispatch({ type: 'SPLASH', data: { display: "block" } });
    }

  }, [isAppReady])


  useEffect(() => {

    // do this only on mobile installs
    if ( winObj.matchMedia('(display-mode: standalone)').matches && appVisibilityState ) {

      if ( prevVisibilityState && prevVisibilityState === 'hidden' && appVisibilityState === 'visible' ) {
        if ( !getCookie("LastAppFetchState") ) {
          setCookie("LastAppFetchState", "fetched", 1);
          winObj.location.reload();
        }
      }

      setPrevVisibilityState( appVisibilityState );
    }

  // eslint-disable-next-line
  }, [appVisibilityState])

  /* Events */

  function onBackClick(){
    if ( store.routeHistoryDepth > 1 ) {
      dispatch( { type: 'ROUTE_HISTORY', data: store.routeHistoryDepth - 1 } )
      history.goBack();
    } else if ( store.routeHistoryDepth === 1 ) {
      winObj.location.href = "/";
    }
  }

  function onScrollReset( pathName ){
    dispatch( { type: 'ROUTE_HISTORY', data: store.routeHistoryDepth + 1 } )
  }

  return (
    <StoreProvider store={store} dispatch={dispatch}>
      <div className="App">
        <div className="App-topBG" />
        <div className="App-bottomBG" />

        <PopUPModal isOpen={store.modal.isOpen} dispatch={dispatch} onDismiss={store.modal.onDismiss} > 
          {store.modal.cmp}
        </PopUPModal>

        <InstallAppPrompt position={store.installPrompt.position} />

        <header className={"App-header" + (store.header.secondaryAction ? " left" : "")}>
          { store.header.allowBackNav ? <LinkButton className="Back-btn" icon={BACK_ICON} name="Back" onButtonClick={onBackClick} /> : null }
          <Logo displayLogo={store.header.logo} user={currentUser} fetchLogo={store.logo.reRender} />
          { store.header.secondaryAction ? 
            <>
              <LinkButton className="Secondary-Action-btn" name={store.header.secondaryAction.name} onButtonClick={store.header.secondaryAction.onActionClick} icon={store.header.secondaryAction.icon} />
              <LinkButton className="Teritiary-Action-btn" name={store.header.tertiaryAction.name} onButtonClick={store.header.tertiaryAction.onActionClick} icon={store.header.tertiaryAction.icon} />
            </>
          : null }
          { store.header.action ?  <LinkButton className="Action-btn" name={store.header.action.name} onButtonClick={store.header.action.onActionClick} icon={store.header.action.icon} /> : null }
        </header>

        <div className="App-splash" style={store.splash}>
          <h4> We are </h4>
          <h2> Inward Strong </h2>
        </div>

        <Loader play={store.loader.play} />
        
        <ScrollToTop onScrollReset={onScrollReset} />

        { isAppReady && !store.loader.play ? (
        <div className="App-container" >
          <Switch>
            <UnAuthRoute path="/GetStarted" user={currentUser} component={GettingStarted} />
            <UnAuthRoute path="/SignUp" user={currentUser} component={SignUp} />
            <UnAuthRoute path="/CreateAccount" user={currentUser} component={CreateAccount} />
            <UnAuthRoute path="/Login" user={currentUser} component={Login} />
            <UnAuthRoute path="/ForgotPassword" user={currentUser} component={ForgotPassword} />
            <UnAuthRoute path="/ConfirmPassword/:code" user={currentUser} component={ConfirmPassword} />
            <AuthRoute path="/UpdatePassword" user={currentUser} component={UpdatePassword} />
            <AuthRoute path="/ConfirmSponsor/:flowType" user={currentUser} component={ConfirmSponsor} />
            <AuthRoute path="/Home" user={currentUser} component={Home} /> 
            <AuthRoute path="/Welcome" user={currentUser} component={WelcomeScreen} /> 
            <AuthRoute path="/CarePathway" user={currentUser} component={CarePathway} /> 
            <AuthRoute path="/Quiz/:quizID" user={currentUser} component={Quiz} /> 
            <AuthRoute path="/Session/:programSlug/:sessionID" user={currentUser} component={SessionDetails} /> 
            <AuthRoute path="/Tools" user={currentUser} component={Tools} /> 
            <Route path="/Article/:articleID" user={currentUser} component={ArticleScreen} />
            <Route path="/WeeklyTip" user={currentUser} component={WeeklyTipScreen} />
            <AuthRoute path="/Settings" user={currentUser} component={Settings} />
            <AuthRoute path="/Profile" user={currentUser} component={Profile} />
            <AuthRoute path="/MindfulMinute" user={currentUser} component={MindfulMinute} />
            <AuthRoute path="/StressCheckin" user={currentUser} component={StressCheckIn} />
            <AuthRoute path="/Tokens" user={currentUser} component={Tokens} />
            <AuthRoute path="/ReportIssue/:type" user={currentUser} component={ReportIssue} />
            <AuthRoute path="/MoodTracker" user={currentUser} component={MoodTracker} />
            <AuthRoute path="/Favourites" user={currentUser} component={FavouritesScreen} />
            <Route path="/Version" component={Version} />
            <Route path="/" component={Root} />
          </Switch>
        </div>
        ) : null }

        { store.footer.display ? <Footer /> : null }
        
      </div>

    </StoreProvider>
  );
}

export default App;


/* App Helpers */

/**
 * Create the initial app store state
 */
function getInitialAppStoreState(){
  return {
    splash: {
      display: "block",
    },
    installPrompt: {
      position: "bottom"
    },
    loader: {
      play: true
    },
    modal: {
      isOpen: false
    },
    header: {
      allowBackNav: false,
      logo: false,
      action: null,
      secondaryAction: null
    },
    logo: {
      reRender: false
    },
    footer: {
      display: true
    },
    goals: [],
    quiz: {},
    tokens: 0,
    userProfile: null,
    routeHistoryDepth: 0
  }
}

/**
 * App store reducer
 * @param {object} state previous state object
 * @param {object} action fired action
 * @return {object} state new state object
 */
function appStoreReducer(state, action){
  switch(action.type){
    case 'SPLASH': 
      return { ...state, splash: action.data }
    case 'INSTALL_PROMPT':
      return { ...state, installPrompt: action.data }
    case 'LOADER': 
      return { ...state, loader: action.data }
    case 'POP_UP_MODAL': 
      return { ...state, modal: action.data }
    case 'HEADER': 
      return { ...state, header: { ...state.header, ...action.data } }
    case `GOALS`:
      return { ...state, goals: action.data }
    case `QUIZ`:
      return { ...state, quiz: action.data }
    case `FOOTER`:
      return { ...state, footer: action.data }
    case `TOKENS`:
      return { ...state, tokens: action.data }
    case `USER`:
      return { ...state, userProfile: action.data }
    case `LOGO`:
      return { ...state, logo: action.data }
    case `ROUTE_HISTORY`: 
      return { ...state, routeHistoryDepth: action.data }
    default: 
      return state;
  }
}

/**
 * get the coupon code if one is present
 * @param { String } location
 * @return { String } Code or null
 */
function getCouponCode( location ){
  let pos = location.hash.indexOf("?")

  if ( pos > 0 ) {
    const urlParams = new URLSearchParams(location.hash.slice( pos + 1 ))
    if ( urlParams.has('BeInwardStrongWith') )
      return urlParams.get('BeInwardStrongWith')
  }

  return null;
}