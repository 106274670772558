import React from "react";
import ISButton from "../button";
import "./messageModal.css";

function MessageModal({ title, icon, buttonTitle, buttonHandler, children }){
    return(
        <div className="messageContainer">
            { icon ? <img className="icon" src={icon} alt="Icon" /> : null }
            <p className="heading"> {title} </p>
            <div className="message">
                { children }
            </div>
            <ISButton type="clear" value={buttonTitle} onClick={buttonHandler} size={{width: "317px", height: "57px"}} />
        </div>
    )
}

export default MessageModal