import React from "react";
import "./index.css";

function Footer(){
    return (
        <div className="Footer">
            <h3> Powered by Inward Strong Inc. </h3>
        </div>
    )
}

export default Footer