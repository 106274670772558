import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth"
import ImageButton from "../../components/ImageButton";
import FeaturedSession from "../../components/FeaturedSession";
import ISCarousel from "../../components/ISCarousel";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import { updateISHeader, createSettingsAction, createPointsLink, createFavsLink } from "../../shared/utils/dispatchHelpers";
import { createMessageModal } from "../../shared/utils/modals";
import winObj from "../../shared/utils/window";
import { getAllPrograms, getISUserProfile, getOrganizationByID, curateMyCarePathway, rearrangeMyCarepathway, updateMyCarePathway, getMyFeaturedModule, startSession, personalizeSessionStates, getActiveProgramName, updateActiveProgram } from "../../shared/middleware";
import { logAutoEvent } from "../../shared/utils/analytics";
import INFO_ICON from "./assets/information-line.svg";
import DATA from "./constant";
import "./index.css";

const { menu } = DATA;

function Home({dispatch, store}){

    const [ auth ] = useState(getAuth())
    const [ userProfile, setUserProfile ] = useState(null);
    const [ sessions, setSessions ] = useState(null);
    const [ programs, setPrograms ] = useState(null);
    const [ myPrograms, setMyPrograms ] = useState(null);
    const [ activeProgram, setActiveProgram ] = useState(null);
    const [ featuredSession, setFeaturedSession ] = useState(null);
    const [ showContent, setShowContent ] = useState(false);

    useEffect(()=>{
        dispatch(updateISHeader(false, true, createSettingsAction(), createFavsLink(), createPointsLink( store.tokens ) ));
        if (winObj.document) winObj.document.title = "InwardStrong - Home"
    // eslint-disable-next-line
    },[dispatch])

    useEffect(()=>{

        if ( auth.currentUser ) {
            Promise.all([ getISUserProfile( auth.currentUser.uid ), getAllPrograms() ])
                .then((values) =>{

                    let [ userProfileSnapshot, allPrograms ] = values
                    let ISUserProfile = userProfileSnapshot.val();

                    getOrganizationByID( ISUserProfile.orgSponsor )
                        .then( ( orgSnapshot ) =>{
                            let org = orgSnapshot.val()

                            setUserProfile( ISUserProfile );

                            setPrograms(allPrograms);

                            setActiveProgram( getActiveProgram(allPrograms, ISUserProfile, org) )

                            logAutoEvent();
                        })
                })
                .catch((error)=>{
                    console.log(" Set Up call has failed - ", error)
                })
        }
    
    // eslint-disable-next-line
    }, [auth])


    useEffect(() => {

        if ( userProfile && sessions && sessions.length > 0 && activeProgram ) {
            setFeaturedSession(getMyFeaturedModule( userProfile, sessions, activeProgram ));
        }

    }, [ sessions, userProfile, activeProgram ])


    useEffect(() => {

        if ( activeProgram && userProfile && programs ) {
            const mySessions = curateMyCarePathway(activeProgram, userProfile);
            setSessions(personalizeSessionStates( userProfile, mySessions, activeProgram.slug ));
            setMyPrograms(programs.filter( ( program ) => { return program.slug !== activeProgram.slug } ));
            setShowContent(true);
        }

    }, [ activeProgram, userProfile, programs ])

    /* Event Handlers */

    function onMenuSelected(item){
        winObj.location.href = item.routeURL
    }

    function onStartSession(module) {

        if ( userProfile && userProfile.startedModules && Object.keys(userProfile.startedModules).length > 0 ) {
            winObj.location.href = "/#/Session/" + activeProgram.slug + "/" + module.id;
            return;
        }

        const okayHandle = () =>{
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: false } })
            winObj.location.href = "/#/Session/" + activeProgram.slug + "/" + module.id;
        }

        const message = "Inward Strong is designed to mirror a therapeutic experience. We encourage you to give yourself time to work through and practice techniques between sessions.<br> <br> If you want to change your session order, simply click the lock icon on the session you want to access next."

        let modalMessage = createMessageModal(module.title, null, "Got It", okayHandle, message)
        dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modalMessage } } )
    }

    function onSessionSelected( sessionCard ) {

        if ( sessionCard.isLocked ) {
            const okayHandle = () =>{

                const newCarePathway = rearrangeMyCarepathway( sessionCard, "unlock", getCurrentCarePathway(sessions) , featuredSession );

                updateMyCarePathway( userProfile.id, newCarePathway, activeProgram.slug)
                    .then(()=>startSession( userProfile, sessionCard, activeProgram.slug ))
                    .finally(()=>{
                        dispatch({ type: 'POP_UP_MODAL', data: { isOpen: false } })
                        winObj.location.href = "/#/Session/" + activeProgram.slug + "/" + sessionCard.id;
                    })
            }

            const message = "Would you like to unlock this module next? <br> <br>  <b> Unlocking this module will place it next-up in your care pathway.</b>"
    
            let modalMessage = createMessageModal("Are you sure?", INFO_ICON, "Yes, I'm sure", okayHandle, message)
            dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modalMessage } } )
        } else {
            window.location.href = "/#/Session/" + activeProgram.slug + "/" + sessionCard.id
        }
    }

    function onProgramSelected( program ) {
        updateActiveProgram( userProfile.id, program.slug )
        setActiveProgram( program );
        setShowContent(false);
    }

    return (
        <div className="Home-Container">
            <h2> Welcome { ( userProfile && userProfile.displayName) ? userProfile.displayName : auth.currentUser.displayName } </h2>

            { showContent ? <>
                <FeaturedSession module={featuredSession} onStartSession={onStartSession} />

                <div className="list-options">
                    { 
                        menu.map(( item ) => {
                            return item.animationData ? <ImageButton key={item.id} className="square" value={item.name} animationData={item.animationData} onClick={()=>onMenuSelected(item)} /> : <ImageButton key={item.id} className="square" value={item.name} logo={item.pictureURL} onClick={()=>onMenuSelected(item)} />
                        })
                    }
                </div>

                { sessions ? <ISCarousel title="My Care Pathway" cards={sessions} onCardSelected={onSessionSelected} /> : null }

                { myPrograms ? <ISCarousel title="All Programs" cards={myPrograms} onCardSelected={onProgramSelected} /> : null }

            </> : null }

        </div>
    )
}

export default StoreConnect(Home)


/* Helpers */

/**
 * Returns my carepathway order
 * @param {Array} list of sessions
 * @returns {Array} session slugs
 */
function getCurrentCarePathway(sessions){
    return sessions.map( (session) => session.slug );
}

/**
 * Returns the currently active program for the user. This is identified by the user's sponsoring org and based on their profile activity.
 * @param {Array} programs All available programs
 * @param {Object} userProfile user profile
 * @param {Object} org Organization
 * @return {Object} program - The current active program for the user
 */
function getActiveProgram( programs, userProfile, org){

    let activeProgramSlug = ( !userProfile.activeProgram && org && org.defaultProgram ) ? org.defaultProgram : getActiveProgramName( userProfile );

    let activeProgram = programs.find(( program ) => {
        return program.slug === activeProgramSlug
    })

    return activeProgram
}