import React from "react";
import PropTypes from "prop-types";
import ImageButton from "../ImageButton";
import SERVICE_ICON from "./assets/service-line.png"
import "./index.css";

function MultipleChoiceQuestion({ question, options, onChoiceSelection, selectedChoice }){

    function onClick(index){
        if (onChoiceSelection) onChoiceSelection(index)
    }

    return (
        <div className="MultipleChoiceQuestion">
            <p className="Question"> { question } </p>
            <div className="Options">
                { options.map((option, index)=>
                    <ImageButton pressed={ index === selectedChoice } className="pill" key={index} value={option} logo={SERVICE_ICON} onClick={()=>{onClick(index)}} />
                )}
            </div>
        </div>
    )
}

MultipleChoiceQuestion.propTypes = {
    question: PropTypes.string,
    options: PropTypes.array,
    onChoiceSelection: PropTypes.func,
    selectedChoice: PropTypes.number
}

MultipleChoiceQuestion.defaultProps = {
    selectedChoice: null
}

export default MultipleChoiceQuestion