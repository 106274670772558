import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Player from "@vimeo/player";
import PLAY_ICON from "./assets/play-mini-line.png";
import PAUSE_ICON from "./assets/pause.png";
import CC_ICON from "./assets/cc.png";
import FS_ICON from "./assets/fullscreen.png";
import "./index.css";

function VideoPlayer(props){

    const { src, autoPlay, displayControls, title, loop, onPlaybackEvent, seekPos } = props
    const iframeRef = useRef();
    const playerRef = useRef();
    const playerChromeRef = useRef();
    const [ playerStyle ] = useState({ width:"100%", height:"100%", position: "relative", zIndex: 2 });
    const [ playbackSrc, setPlaybackSrc ] = useState(null);

    const [ playerState, setPlayerState ] = useState(null);
    const [ playIcon, setPlayIcon ] = useState( null )
    const [ cc, setCC ] = useState(false)
    const [ seek, setSeek ] = useState(0);

    useEffect(()=>{

        if ( playbackSrc && iframeRef.current ){

            playerRef.current = new Player( iframeRef.current )
            setPlayerEvents( playerRef.current );
        }

        // eslint-disable-next-line
    }, [ playbackSrc ])

    useEffect(()=>{

        let hideVimeoStyle = "title=0&;sidedock=0&;byline=0&;playsinline=1&;portrait=0&;transparent=true"
        let autoPlayStatus = autoPlay ? "autoplay=1&;autopause=0&;muted=1" : "autoplay=0&;autopause=0&;muted=0";
        let loopStatus = loop ? "loop=1" : "loop=0"
        let controlsStatus = displayControls ? "background=0&;controls=1" : "background=0&;controls=0";
        seekPos ? setSeek( seekPos ) : setSeek(0);

        (autoPlay) ? setPlayIcon( PAUSE_ICON ) : setPlayIcon(PLAY_ICON);

        setPlaybackSrc( src + "?" + hideVimeoStyle + "&;" + autoPlayStatus + "&;" + loopStatus + "&;" + controlsStatus );

    }, [src, autoPlay, displayControls, loop, seekPos])

    useEffect(()=>{

        if (playerState === 'PLAY') {
            setPlayIcon(PAUSE_ICON)
            if ( seek > 0 ) { 
                playerRef.current.setCurrentTime( seek );
                setSeek(0);
            }
        } else {
            setPlayIcon(PLAY_ICON)
        }

    // eslint-disable-next-line
    }, [playerState])


    const onPlayPauseClick = (e) => {
        
        if ( playerState === 'PLAY' ) 
            playerRef.current.pause();
        else 
            playerRef.current.play();

    }

    const onCaptionsClick = (e) =>{
        (cc) ? playerRef.current.disableTextTrack() : playerRef.current.enableTextTrack("en");
        setCC(!cc);
    }

    const onFSClick = (e) => {
        if (playerChromeRef.current) {
            playerChromeRef.current.requestFullscreen();
          } else {
            document.exitFullscreen();
          }
    }

    const setPlayerEvents = ( player ) => {

        player.on('pause', ()=>{ setPlayerState('PAUSED') });
        player.on('play', ()=>{ setPlayerState('PLAY') });
        player.on('timeupdate', ( data )=>{
            if ( onPlaybackEvent ) onPlaybackEvent( { type: 'timeupdate', data } )
        })
        player.on('ended', (data)=>{
            if ( onPlaybackEvent ) onPlaybackEvent( { type: 'ended', data } )
        })
    }


    return (
        <>
            { playbackSrc ? 
                <div className="player" ref={playerChromeRef} >

                    <iframe key={playbackSrc} ref={iframeRef} src={playbackSrc} frameBorder="0" allowtransparency="yes" scrolling="no"
                        allow="autoplay;" style={playerStyle} title={title} ></iframe>

                    { !displayControls ? 
                        <div className="controls">

                            <img src={playIcon} alt="play/pause" onClick={onPlayPauseClick} />

                            <img src={CC_ICON} alt="close captions" onClick={onCaptionsClick} />

                            <img src={FS_ICON} alt="full screen" onClick={onFSClick} />

                        </div>
                    : null }

                </div> 
            : null }
        </>
    )
}

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
    displayControls: PropTypes.bool,
    title: PropTypes.string,
    loop: PropTypes.bool,
    seekPos: PropTypes.number,
    onPlaybackEvent: PropTypes.func
}

VideoPlayer.defaultProps = {
    autoPlay: false,
    displayControls: false,
    title: "",
    loop: false,
    seekPos: 0
}


export default VideoPlayer