import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";
import "./Imagebutton.css"

function ImageButton( props ){

    const { animationData } = props;
    const loadRef = useRef();
    let lottieRef = useRef();

    useEffect(() => {

        if ( animationData ) {

            lottieRef.current = lottie.loadAnimation(
                {
                    container: loadRef.current,
                    renderer: 'svg',
                    loop: 3,
                    autoplay: false,
                    animationData: animationData
                }
            )

            lottieRef.current.play()
        }

        return (()=>{
            if ( lottieRef && lottieRef.current ) lottieRef.current.stop()
        })

    }, [ animationData ])


    const onButtonClick = (e)=>{
        if ( !props.disabled ) props.onClick(e);
    }

    return(
        <button disabled={props.disabled} className={"ImageButton " + props.className + (props.pressed ? " pressed" : "") } type="button" onClick={onButtonClick} >
            { props.logo ? <img src={props.logo} alt="logo" /> : null }
            { animationData ? <div style={{display: "block", width: "42px", height: "37px"}} ref={loadRef}></div> : null }
            <p> { props.value } </p>
        </button>
    )
}

ImageButton.propTypes = {
    logo: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    pressed: PropTypes.bool,
    animationData: PropTypes.object,
}

ImageButton.defaultProps = {
    disabled: false,
    pressed: false
}

export default ImageButton