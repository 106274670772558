import React, { useRef, useState, useEffect } from "react";
import { getAuth, confirmPasswordReset } from "@firebase/auth";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ISButton from "../../components/button";
import { createMessageModal } from "../../shared/utils/modals";
import { updateISHeader, createLoginAction } from "../../shared/utils/dispatchHelpers";
import FAILURE_IMAGE_ICON from "../../app/assets/close-circle-line.svg";
import SUCCESS_IMAGE_ICON from "../../app/assets/checkbox-circle-line.svg";
import ERRORS from '../../app/constant';
import "./index.css";

var { AUTH_ERRORS } = ERRORS;

function ConfirmPassword({ match, dispatch }){
    const { code } = match.params;

    const [ buttonState, setButtonState ] = useState(true);
    const password = useRef();
    const confirmPassword = useRef();

    useEffect(()=>{
        dispatch(updateISHeader(true, true,createLoginAction()))
    }, [dispatch])

    const onPasswordChange = () => {
        setButtonState(password.current.value.length === 0 || confirmPassword.current.value.length === 0)
    }

    const onResetPassword = () => {
        let pwd = password.current.value;
        let cPwd = confirmPassword.current.value;

        if ( pwd !== cPwd ){
            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, AUTH_ERRORS.CREATE_ACCOUNT_ERRORS["auth/password-not-match"]) } })
            return;
        }

        dispatch({ type: 'LOADER', data: { play: true } });
        // Save password in firebase
        confirmPasswordReset(getAuth(), code, pwd)
            .then(()=>{
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Success", SUCCESS_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Your password has been reset" ) } })
            })
            .catch(()=>{
                dispatch({ type: 'POP_UP_MODAL', data: { isOpen: true, cmp: createMessageModal( "Error", FAILURE_IMAGE_ICON, "Okay", ()=>{dispatch({type: `POP_UP_MODAL`, data: { isOpen: false }})}, "Something went wrong. Please try again") } })
            })
            .finally(()=>{
                dispatch({ type: 'LOADER', data: { play: false } });
            })

    }


    return (
        <div className="ConfirmPassword-Container">
            <h2> Welcome Back, </h2>
            <h4> Reset your password? </h4>

            <div className="Password InputField">
                <p> Enter your new password </p>
                <input type="password" ref={password} id="password" name="password" onChange={onPasswordChange} />
            </div>

            <div className="Password InputField">
                <p> Confirm your new password </p>
                <input type="password" ref={confirmPassword} id="password-confirm" name="password-confirm" onChange={onPasswordChange} />
            </div>

            <div className="SignIn-Container">
                <ISButton disabled={buttonState} value="Submit" onClick={onResetPassword} /> 
            </div>

        </div>
    )

}

export default StoreConnect(ConfirmPassword)