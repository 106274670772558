import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css";

function Favourite( { set, onClick } ){

    const [ isFavourited, setIsFavourited ] = useState(false);

    useEffect( () =>{
        setIsFavourited( set );
    }, [ set ] )

    function onFavouriteClick(e){
        if ( onClick ) onClick( !isFavourited, e );
    }

    return (
        <div className={ isFavourited ? "heart" : "heart unset" } onClick={onFavouriteClick} >
        </div>
    )
}

Favourite.propTypes = {
    set: PropTypes.bool,
    onClick: PropTypes.func
}

Favourite.defaultProps = {
    set: false,
}

export default Favourite