import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import lottie from "lottie-web"
import lottieData from './assets/spinner.json'
import './loader.css'

/**
 * Displays an Animated Spinner in the middle of the screen.
 * Exposes params to show and hide the loader.
 */
function Loader({play}){
    const loadRef = useRef();
    let lottieRef = useRef();

    useEffect(()=>{
        lottieRef.current = lottie.loadAnimation(
            {
                container: loadRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: lottieData
            }
        )
    }, [])

    useEffect(()=>{
        ( lottieRef.current && play ) ? lottieRef.current.play() : lottieRef.current.stop()
    }, [play])

    return(<div className="loader" style={{display: (play ? "block" : "none")}} ref={loadRef}></div>)
}

Loader.propTypes = {
    play: PropTypes.bool
}

Loader.defaultProps = {
    play: false
}

export default Loader