/**
 * Pull data from contentful
 */

var contentful = require('contentful');
const SPACE_ID = "kh8ymnzeu1z1";
const ACCESS_VAL = "pMqYU8iue3G8h3QJSU2n7AbutAa_uBrOw1vgbIrN-UM";

const client = contentful.createClient({
    space: SPACE_ID,
    accessToken: ACCESS_VAL
});


/**
 * Fetch a list of entities from contentful CMS
 * @param {Object} - Search filter params as listed by contentful
 * @returns {Promise}
 */
function getEntries( searchParams ){
    return client.getEntries(searchParams)
}


/**
 * Fetch a specific entry within Contentful CMS
 * @param {String} - Entry ID as a string
 * @returns {Promise}
 */
 function getEntry( entryID ){
    return client.getEntry(entryID)
}

/**
 * Returns a list of all available Tags
 * @returns {Promise}
 */
function getTags(){
    return client.getTags()
}

export { getEntries, getEntry, getTags }

