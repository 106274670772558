import React, { useEffect } from "react";
import { StoreConnect } from "../../components/HOC/StoreConnector";
import ImageButton from "../../components/ImageButton"
import { updateISHeader } from "../../shared/utils/dispatchHelpers";
import { createMessageModal } from "../../shared/utils/modals";
import winObj from "../../shared/utils/window";
import Events, { logAnalyticEvent, createEventData } from "../../shared/utils/analytics";
import DATA from "./constant";
import "./index.css"

const { options } = DATA

function CarePathway( { dispatch, store } ){

    useEffect(()=>{
        dispatch(updateISHeader(false, true))
    },[dispatch])

    const onGoalSelected = (selectedOption) => {

        const okayHandle = () => {

            logAnalyticEvent( Events.CAREPATHWAY_TYPE, createEventData({ carepathway_type: selectedOption.name, sponsoring_org: store.userProfile.orgSponsor }) );

            dispatch({ type: 'POP_UP_MODAL', data: { isOpen: false } })
            winObj.location.href = "/#/Quiz/care-pathway-needs-assessment"
        }

        let modalMessage = createMessageModal("", selectedOption.messageIcon, "Let's Go", okayHandle, selectedOption.message)
        dispatch( { type: 'POP_UP_MODAL', data: { isOpen: true, cmp: modalMessage } } )
    }

    return(
        <>
        <div className="CarePathway-Container">

            <div className="titles">
                <h2> Welcome { store.userProfile && store.userProfile.displayName ? store.userProfile.displayName : ""} </h2>
                <h4> Choose my pathway </h4>
            </div>

            <div className="list-options">
                { 
                    options.map(( option ) =>
                        <ImageButton key={option.id} className={"square "+ option.className} value={option.name} logo={option.pictureURL} onClick={()=>onGoalSelected(option)} />
                    )
                }
            </div>

            <div className="Background">

            </div>

        </div>
        </>
    )
}

export default StoreConnect(CarePathway)