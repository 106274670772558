import React from "react";

function Version(){
    return (
        <>
        { process.env.REACT_APP_VERSION 
            ? <p style={{marginTop: "50px"}}> { process.env.REACT_APP_VERSION } </p>    
            : <p style={{marginTop: "50px"}}> No Version Tag on server </p>   
        }
        </>
    )
}

export default Version